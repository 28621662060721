import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import {
  Category,
  Country,
  City,
  ServiceType,
  AreaCoverage,
  Product,
  Paging,
  Profile,
  ProfileResponse,
  DropDownReponse,
} from "../../types";
import http from "../../utils/http";

export const fetchServices = createAsyncThunk(
  "category/fetchService",
  async (_, thunkAPI) => {
    try {
      const url = `${config.endpoints.services.fetchServices}`;
      const {
        data: { result },
      } = await http.get<{ result: ServiceType[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCategoryProducts = createAsyncThunk(
  "product/fetchCategoryProducts",
  async (id: string, thunkAPI) => {
    try {
      const url = `${config.endpoints.products.fetchProducts}/${id}`;
      const {
        data: { result },
      } = await http.get<{ result: Category[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllProducts = createAsyncThunk(
  "product/fetchAllProducts",
  async (values: any, thunkAPI) => {
    try {
      let url = config.endpoints.products.fetchAllProducts;

      if (values.pageNo && values.pageSize) {
        if (Number(values.pageNo) > 0) {
          url += `?pageNumber=${values.pageNo}`;
          url += `&pageSize=${values.pageSize}`;
        }
      }

      const {
        data: { result, paging },
      } = await http.get<{ result: Product[]; paging: Paging }>(url);
      return { result, paging };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCountries = createAsyncThunk(
  "country/fetchCountries",
  async (_, thunkAPI) => {
    try {
      const url = `${config.endpoints.country.fetchCountries}`;
      const {
        data: { result },
      } = await http.get<{ result: Country[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCities = createAsyncThunk(
  "city/fetchCities",
  async (Id: Number, thunkAPI) => {
    try {
      const url = `${config.endpoints.city.fetchCities}/${Id}`;

      const {
        data: { result },
      } = await http.get<{ result: City[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAreaCoverage = createAsyncThunk(
  "areacoverage/fetchAreaCoverage",
  async (_, thunkAPI) => {
    try {
      const url = `${config.endpoints.areacoverage.fetchAreaCoverage}`;
      const {
        data: { result },
      } = await http.get<{ result: DropDownReponse[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveProduct = createAsyncThunk(
  "product/approveProduct",
  async (id: bigint, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.approveProduct}/${id}`;

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const rejectProduct = createAsyncThunk(
  "product/rejectProduct",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.rejectProduct}/${values.ProductId}`;

      let rejectedReason = "Your product has been rejected.";
      if (values.Reason) {
        rejectedReason = values.Reason;
      }

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url, { Reason: rejectedReason });
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "product/deleteCategory",
  async (id: bigint, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.deleteCategory}/${id}`;

      const {
        data: { result },
      } = await http.delete<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id: bigint, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.deleteCategory}/${id}`;

      const {
        data: { result },
      } = await http.delete<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAllUsers",
  async (values: any, thunkAPI) => {
    try {
      let url = config.endpoints.setups.fetchAllUsers;

      if (values.searchText && values.searchText.length > 0) {
        url += `?search=${values.searchText}`;
        if (values.pageNo && values.pageSize) {
          if (Number(values.pageNo) > 0) {
            url += `&pageNumber=${values.pageNo}`;
            url += `&pageSize=${values.pageSize}`;
          }
        }
      } else {
        if (values.pageNo && values.pageSize) {
          if (Number(values.pageNo) > 0) {
            url += `?pageNumber=${values.pageNo}`;
            url += `&pageSize=${values.pageSize}`;
          }
        }
      }

      const {
        data: { result, paging },
      } = await http.get<{ result: ProfileResponse[]; paging: Paging }>(url);
      return { result, paging };
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
