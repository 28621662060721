import React from "react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../hooks";
import * as SupplierService from "../../store/supplier/supplier.action";
import { Form, Input, notification, Select, Spin } from "antd";
import { Link } from "react-router-dom";
import ConsultantFooter from "../Consultant/HomeFooter";
import { UserType } from "../../enums";
import SiteHeader from "../Supplier/SiteHeader";
import * as AuthService from "../../store/auth/auth.actions";
import { displayErrorMessage, displayLoadingMessage, displaySuccessNotification } from "../../utils/notifications";
import { clearAuthError, clearAuthStatus } from "../../store/auth/auth.slice";
import { PreLoader } from "../Components";

const { Option } = Select;

export const HomePage = () => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const initSearch = (searchText: string) => {
    let paramString = `?searchText=${searchText}`;

    if (areaCoverage) {
      paramString += `&areaCoveraage=${areaCoverage}`;
    }

    dispatch(SupplierService.fetchSupplier(paramString));
  };

  const { user } = useAppSelector((state) => state.auth);

  const {
    status: listStatus,
    error: listError,
    entities: listEntities,
  } = useAppSelector((state) => state.list);

  const { status: authStatus, error: authError } = useAppSelector(
    (state) => state.auth
  );

  const [areaCoverage, setAreaCoverage] = useState<number>();

  // useEffect(() => {
  //   dispatch(SupplierService.fetchSupplier(""));
  // }, [dispatch]);

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select
        style={{ width: "150px" }}
        placeholder="City"
        onChange={setAreaCoverage}
      >
        <option></option>
        {/* {listEntities.areaCoverages?.map((area, index) => (
          <Option value={area.id} key={index}>
            {area.city}
          </Option>
        ))} */}
      </Select>
    </Form.Item>
  );

  useEffect(() => {
    if (authError) {
        displayErrorMessage(authError);
        dispatch(clearAuthError());
    }
}, [authError]);

useEffect(() => {
    if (authStatus === "sendEmailResolved") {
        displaySuccessNotification("Email has been sent");
    }

    if (authStatus.includes("Pending")) {
        displayLoadingMessage(<PreLoader/>);
    } else {
        notification.close("loading");
    }

    return () => {
        dispatch(clearAuthStatus());
    };
}, [authStatus]);

  const onFormSubmit = (formData: any) => {
    formData.userId = 4;

    dispatch(AuthService.sendNotification(formData));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useScrollToTop();

  return (
    <>
      <SiteHeader />

      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100"
              src="images/hero.jpg"
              alt="First slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>
                High-Impact knowledge,
                risk <br /> free investment.
              </h5>
              <p>Empowering consultants and businesses alike; Tap into a network of top-tier consultants and service seekers.</p>
              <a className="btn-hero" href="/signup">
                Connect Now
              </a>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100"
              src="/images/Connect.png"
              alt="First slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>
                Connect. Collaborate. Succeed
              </h5>
              <p>Join the TradInn network and find the perfect match for your consulting services.</p>
              <a className="btn-hero" href="/signup">
                Connect Now
              </a>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100"
              src="/images/strategic.png"
              alt="First slide"
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>
                Align your investment with <br /> top-tier consultants
              </h5>
              <p>Join the TradInn journey, where every connection is an opportunity.</p>
              <a className="btn-hero" href="/signup">
                Connect Now
              </a>
            </div>
          </div>
        </div>

        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

      <div
        id=""
        className="container-fluid section-container text-center bg-white"
      >
        <div className="container">
          <div className="section-heading">
            <h1 className="title">About TradInn</h1>
          </div>
          <p className="my-5">
            TradInn strives to simplify work processes by connecting consultants
            with service seekers. Our platform is designed to provide a
            user-friendly experience catering to consultants and customers. We
            are committed to offering the necessary tools and support to ensure
            a smooth collaboration experience for both parties.
          </p>

          <a className="btn-gold btn-center" href="/infohub">
            Explore
          </a>
        </div>
      </div>

      <div className="container-fluid section-container bg-lightgray text-center">
        <div className="container">
          <div className="section-heading">
            <h1 className="title">
              Find Professional Solutions
              <br /> from Expert Consultants
            </h1>
          </div>
          <p className="w-75 my-5 mx-auto">
            TradInn aims to transform how consultants from various sectors connect
            with customers across the UK. We strive to lead the way in
            delivering innovative consulting services that empower consultants
            and customers.
          </p>

          <div className="row text-lg-left">
            <aside className="col-lg-5">
              <div className="section-heading">
                <img className="title-icon" src="/images/prospects.png" />
                <h1 className="title">Prospects</h1>
              </div>
              <b>
                <p className="list-large">
                  Signup as a consultant
                  <br />
                  Setup your profile & Services
                  <br />
                  Find incoming inquiries
                  <br />
                  Select an inquiry
                  <br />
                  Connect with the prospect
                  <br />
                  <br />
                  <a className="btn-signup" href="/signup">
                    Sign Up <i className="fas fa-chevron-right"></i>
                  </a>
                </p>
              </b>
            </aside>
            <aside className="col-lg-5 offset-lg-2">
              <div className="section-heading">
                <img className="title-icon" src="/images/solutions.png" />
                <h1 className="title">Solutions</h1>
              </div>
              <b>
                <p className="list-large">
                  Signup as a customer
                  <br />
                  Explore consultants
                  <br />
                  Post inquiry to consultant
                  <br />
                  Connect with a consultant
                  <br />
                  <br />
                  <a className="btn-signup" href="/signup">
                    Sign Up <i className="fas fa-chevron-right"></i>
                  </a>
                </p>
              </b>
            </aside>
          </div>
        </div>
      </div>

      <div className="container-fluid section-container text-center bg-white">
        <div className="container">
          <div className="section-heading">
            <h1 className="title">
              Your One-Stop platform for <br /> business consultancy
            </h1>
          </div>
          <p className="w-75 mx-auto my-5">
            TradInn creates an ecosystem where consultants and customers
            collaborate to achieve tasks. Our platform breaks down barriers,
            bridges gaps, and fosters meaningful connections, resulting in
            exceptional outcomes.
          </p>
          <a className="btn-gold btn-center" href="/infohub">
            Explore
          </a>
        </div>
      </div>

      {/* <div className="container-fluid section-container text-center bg-white">
        <div className="container">
          <div className="section-heading">
            <h1 className="title">CONSULTANTS</h1>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <figure className="consultant-card">
                <img src="/images/c1.svg" />
                <figcaption>
                  <h4>Patrick Ruijs</h4>
                  <p>Consumer Insights Practice Leader</p>
                </figcaption>
                <a className="btn-cons" href="#">
                  Connect
                </a>
              </figure>
            </div>
            <div className="col-lg-4">
              <figure className="consultant-card">
                <img src="/images/c1.svg" />
                <figcaption>
                  <h4>Patrick Ruijs</h4>
                  <p>Consumer Insights Practice Leader</p>
                </figcaption>
                <a className="btn-cons" href="#">
                  Connect
                </a>
              </figure>
            </div>
            <div className="col-lg-4">
              <figure className="consultant-card">
                <img src="/images/c1.svg" />
                <figcaption>
                  <h4>Patrick Ruijs</h4>
                  <p>Consumer Insights Practice Leader</p>
                </figcaption>
                <a className="btn-cons" href="#">
                  Connect
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid section-container bg-lightgray text-center">
        <div className="container">
          <div className="section-heading">
            <h1 className="title">LET’S CONNECT</h1>
          </div>

          <Form
            form={form}
            onFinish={onFormSubmit}
            onFinishFailed={onFinishFailed}
            className="form-letsconnect" name="form">
            <div className="row">
              <div className="col-lg-6">
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "* First Name",
                    },
                  ]}>
                  <input
                    className="input"
                    placeholder="First Name"
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "* Last Name",
                    },
                  ]}>
                  <input
                    className="input"
                    placeholder="Last Name"
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "* Email",
                    },
                  ]}>
                  <input
                    className="input"
                    placeholder="Email Address"
                    type="email"
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  name="phone">
                  <input
                    className="input"
                    placeholder="Contact"
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "* Wirte your message",
                },
              ]}
            >
              <textarea
                className="textarea"
                placeholder="Write your message here"
              ></textarea>
            </Form.Item>

            <span id="button-text">
              {authStatus === "sendEmailPending" ? (
                <Spin size="small" />
              ) : (
                <input className="submit" type="submit" value="Submit" />
              )}
            </span>

            {/* <input
              className="submit"
              name="submit"
              type="submit"
              value="Submit"
            /> */}
          </Form>
        </div>
      </div>

      <ConsultantFooter />
    </>
  );
};
