import { Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import {
  clearListError,
  clearListStatus,
} from "../../../store/lists/lists.slice";
import { clearSetupError } from "../../../store/setups/setups.slice";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { CategorySetupPage } from "../Category";
import { CitySetupPage } from "../City";
import { CountrySetupPage } from "../Country";
// import { CoverageAreaSetupPage } from "../CoverageArea";
import { ProductListPage } from "../Products";
import * as ListService from "../../../store/lists/list.action";
import * as SetupService from "../../../store/setups/setups.actions";
import { Helmet } from "react-helmet-async";
import { UserList } from "../../Components/userList";
import { AdminSubscriptionPage } from "../Subscription";
import { ActivateSubscriptionPage } from "../ActivateSubscription";
import { useNavigate } from "react-router-dom";
import AdminTemplate from "./AdminTemplate";
import { AdminCustomerList } from "../Customer/List";
import { AdminConsultantList } from "../Consultant/List";
import { AdminInquiryList } from "../Inquiriy/List";
import AdminInquiryViewPage from "../Inquiriy/View";

export const AdminDashBoardPage = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { error: setupError } = useAppSelector((state) => state.setup);
  const { error: listError, status: listStatus } = useAppSelector(
    (state) => state.list
  );

  const [activeTabIndex, setActiveTabIndex] = useState<string>("1");
  const [forceEffect, setForceEffect] = useState(new Date().getTime());

  useEffect(() => {
    if (setupError || listError) {
      displayErrorMessage(setupError || listError);
    }

    dispatch(clearSetupError());
    dispatch(clearListError());
  }, [dispatch, setupError, listError]);

  useEffect(() => {
    if (listStatus === "deleteCategoryResolved") {
      displaySuccessNotification("Category has been deleted.");

      dispatch(clearListStatus());
    }
  }, [dispatch, listStatus]);

  const onTabIndexChanged: TabsProps["onChange"] = (key) => {
    if (key === "0") {
      // dispatch(ListService.fetchAllProducts({ pageNo: 1, pageSize: 10 }));
      setForceEffect(Math.random());

      // navigate("/admin/products");
    } else if (key === "1") {
      // dispatch(ListService.fetchCountries());
      // navigate("/admin/country");
    } else if (key === "2") {
      // dispatch(ListService.fetchCities(0));
      navigate("/admin/city");
    } else if (key === "3") {
      // dispatch(ListService.fetchAreaCoverage());
      navigate("/admin/area");
    } else if (key === "4") {
      // dispatch(SetupService.fetchCategories());
      navigate("/admin/category");
    } else if (key === "5") {
      // dispatch(ListService.fetchAllUsers({ pageNo: 1, pageSize: 10 }));
      navigate("/admin/users");
    } else if (key === "6") {
      // dispatch(SetupService.fetchSubscriptions({ pageNo: 1, pageSize: 10 }));
      navigate("/admin/subscription");
    } else if (key === "7") {
      dispatch(
        SetupService.fetchSubscriptionsForActivation({
          pageNo: 1,
          pageSize: 10,
        })
      );
    }

    setActiveTabIndex(key);
  };

  useScrollToTop();

  return (
    <>
      <AdminTemplate>
        <div className="row">
          <div className="col-lg-12" style={{ textAlign: "left" }}>
            <Tabs type="card" onChange={onTabIndexChanged}>
              <Tabs.TabPane tab="Customer list" key="0">
                <AdminCustomerList force={forceEffect} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Consultant list" key="1">
                <AdminConsultantList force={forceEffect} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Inquiry list" key="2">
                <AdminInquiryList force={forceEffect} />
                <AdminInquiryViewPage />
              </Tabs.TabPane>
              {/* <Tabs.TabPane tab="Location" key="3">
                <CoverageAreaSetupPage />
              </Tabs.TabPane> */}
              {/* <Tabs.TabPane tab="Category" key="4">
                <CategorySetupPage />
              </Tabs.TabPane> */}
              {/* <Tabs.TabPane tab="Users" key="5">
                <UserList />
              </Tabs.TabPane> */}
              <Tabs.TabPane tab="Subscriptions" key="6">
                <AdminSubscriptionPage />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Activate Subscriptions" key="7">
                <ActivateSubscriptionPage />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </AdminTemplate>
    </>
  );
};
