import React from "react";
import CustomerTemplate from "../Template";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Row,
  Upload,
  UploadProps,
  message,
  notification,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import * as AuthService from "../../../store/auth/auth.actions";
import { clearAuthStatus } from "../../../store/auth/auth.slice";
import { PreLoader } from "../../Components";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import config from "../../../config";
import { getAuthToken } from "../../../utils/helper";
import * as routes from "../../../constants/routes";

const { Item } = Form;

export const CustomerManageProfilePage = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { status, error, user } = useAppSelector((state) => state.auth);

  // const [userAreas, setUserAreas] = React.useState<any[]>([])

  const {
    status: listStatus,
    error: listError,
    entities,
  } = useAppSelector((state) => state.list);

  const [areasOfFocus, setAreasOfFocus] = React.useState<number[]>([]);

  const onChange = (checkedValues: any) => {
    setAreasOfFocus(checkedValues);
  };

  React.useEffect(() => {
    if (status === "userProfileUpdated" && !error) {
      displaySuccessNotification("Profile has been updated");

      dispatch(AuthService.loadCurrentUser());

      dispatch(clearAuthStatus());
    }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [status, error]);

  const onFormSubmit = (values: any) => {
    if (form.getFieldValue("Image")) {
      values.Image = form.getFieldValue("Image");
    }
    values.AreaOfFocus = areasOfFocus;

    dispatch(AuthService.createProfile(values));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  React.useEffect(() => {
    form.setFieldsValue({
      FirstName: user?.firstName,
      LastName: user?.lastName,
      MobileNumber: user?.mobileNumber,
      PhoneNumber: user?.phoneNumber,
      CompanyName: user?.companyName,
      CompanyInformation: user?.companyInformation,
      PostalCode: user?.postalCode,
      CountryId: user?.country?.id,
      CityId: user?.city?.id,
      Address: user?.address,
    });

    setAreasOfFocus(user?.areaOfFocus?.map((area) => Number(area.id)) || []);
  }, []);

  const props: UploadProps = {
    name: "file",
    action: `${config.baseURI}${config.endpoints.file.uploadFile}?type=user`,
    headers: { Authorization: `Bearer ${getAuthToken()}` },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        if (info.file && info.file.response.result) {
          form.setFieldsValue({ Image: info.file.response.result });
        }
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // React.useEffect(() => {
  //     if (listStatus === "fetchAreaCoverageSuccess") {
  //         const newAreaList: any[] = [];
  //
  //         let newArea: any = {};
  //
  //         setUserAreas([])
  //
  //         entities?.areaCoverages?.forEach(area => {
  //             newArea = area;
  //
  //             newArea.selected = user?.areaOfFocus?.some(uarea => String(uarea.id) === String(area.id))
  //
  //             newAreaList.push(newArea);
  //         })
  //         setUserAreas((prevCol) => [...prevCol, ...newAreaList])
  //     }
  // }, [user, entities])

  return (
    <>
      <CustomerTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Customer Profile </h1>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <Form
                      form={form}
                      id="regForm"
                      className="user-form"
                      layout="vertical"
                      onFinish={onFormSubmit}
                      onFinishFailed={onFinishFailed}
                      autoComplete="false"
                    >
                      <section>
                        <h4>Personal Details</h4>
                        <div className="row">
                          <aside className="col-lg-6">
                            <Item name="FirstName" label="First Name">
                              <input
                                className="input-text"
                                placeholder="First Name"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="LastName" label="Last Name">
                              <input
                                className="input-text"
                                placeholder="last Name"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item
                              name="MobileNumber"
                              label="Mobile Phone Number"
                            >
                              <input
                                className="input-text"
                                placeholder="Mobile Number"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="PhoneNumber" label="Phone Number">
                              <input
                                className="input-text"
                                placeholder="Phone Number"
                              />
                            </Item>
                          </aside>
                        </div>
                        <h4 className="mt-4">Company Address</h4>
                        <div className="row">
                          <aside className="col-lg-12">
                            <Item
                              name="CompanyName"
                              label="Company name (including legal form)"
                            >
                              <input
                                className="input-text"
                                placeholder="Company Name"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-12">
                            <Item
                              name="CompanyInformation"
                              label="Additional company information"
                            >
                              <input
                                className="input-text"
                                placeholder="Additional Company Information"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="Address" label="Street">
                              <input
                                className="input-text"
                                placeholder="Street"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="PostalCode" label="Postal Code">
                              <input
                                className="input-text"
                                placeholder="Postal Code"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="CountryId" label="Country">
                              <select className="form-select">
                                <option value="-1">Select Country</option>
                                {entities.countries?.map((country, index) => (
                                  <option value={country.id} key={index}>
                                    {country.title}
                                  </option>
                                ))}
                              </select>
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="CityId" label="City">
                              <select className="form-select">
                                <option value="-1">Select City</option>
                                {entities.cities?.map((city, index) => (
                                  <option value={city.id} key={index}>
                                    {city.title}
                                  </option>
                                ))}
                              </select>
                            </Item>
                          </aside>
                        </div>
                        <h4 className="mt-4">Choose Area of Focus</h4>
                        <Checkbox.Group
                          name="AreaOfFocus"
                          style={{ width: "100%" }}
                          onChange={onChange}
                          defaultValue={user?.areaOfFocus?.map((area) =>
                            Number(area.id)
                          )}
                        >
                          {entities?.areaCoverages?.map((area, index) => (
                            <Row key={index.toString()}>
                              <Col>
                                <Checkbox value={area.id}></Checkbox>
                              </Col>
                              <Col>{area.title}</Col>
                            </Row>
                          ))}
                        </Checkbox.Group>
                        <h4 className="mt-4">Add Profile Image</h4>
                        {user?.image && (
                          <aside className="col-lg-6">
                            <Avatar
                              size={150}
                              src={
                                <Image
                                  preview={false}
                                  src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user.image}&type=user`}
                                />
                              }
                              icon={<UserOutlined />}
                              className="mb-1"
                            />
                          </aside>
                        )}
                        <Upload {...props} maxCount={1}>
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>{" "}
                      </section>

                      {/* <section className="tab">
                        <h4 className="mt-4">Email Address</h4>
                        <div className="row">
                          <aside className="col-lg-12">
                            <label>Enter new e-mail address here</label>
                            <input
                              className="input-text"
                              type="text"
                              placeholder="Enter new e-mail address here"
                            />
                          </aside>
                        </div>

                        <div className="row">
                          <h4 className="mt-4">Password</h4>
                          <aside className="col-lg-12">
                            <label>Enter Old Password</label>
                            <input
                              className="input-text"
                              type="password"
                              placeholder="Enter Old Password"
                            />
                          </aside>
                          <aside className="col-lg-12">
                            <label>Enter New Password</label>
                            <input
                              className="input-text"
                              type="password"
                              placeholder="Enter New Password"
                            />
                          </aside>
                          <aside className="col-lg-12">
                            <label>Repeat New Password</label>
                            <input
                              className="input-text"
                              type="password"
                              placeholder="Repeat New Password"
                            />
                          </aside>
                        </div>
                      </section> */}

                      <div style={{ overflow: "auto" }}>
                        <div className="form-buttons">
                          {/* <button
                            type="button"
                            id="prevBtn"
                            onClick={() => window.nextPrev(-1, "tab")}
                          >
                            <i className="fas fa-arrow-left"></i>Previous
                          </button> */}
                          <button
                            type="submit"
                            id="nextBtn"
                            // onClick={() => window.nextPrev(1, "tab")}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomerTemplate>
    </>
  );
};
