import React, { useState } from "react";
import { Form, Input, Radio, Spin } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CreateAccountData, LoginData } from "../../types";
import * as AuthService from "../../store/auth/auth.actions";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../utils/notifications";
import { clearAuthError, clearAuthStatus } from "../../store/auth/auth.slice";
import { UserType } from "../../enums";
import { PreLoader } from "../Components";
import * as routes from "../../constants/routes";
import ConsultantFooter from "../Consultant/HomeFooter";
import SiteHeader from "../Supplier/SiteHeader";
const { Item } = Form;
const { Password } = Input;

export const SignupPage = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { error, status } = useAppSelector((state) => state.auth);

  const [userType, setUserType] = useState(0);

  const navigate = useNavigate();

  const onFormSubmit = (formData: CreateAccountData) => {
    dispatch(AuthService.createAccount(formData));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);

      dispatch(clearAuthError());
    }
  }, [error]);

  useEffect(() => {
    if (status === "userSignedUp" && !error) {
      displaySuccessNotification(
        "Account has been created, Please check your email for Activation."
      );

      navigate(routes.LOGIN);

      dispatch(clearAuthStatus());
    }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    }
  }, [dispatch, status]);

  useScrollToTop();

  return (
    <>
      <SiteHeader />

      <div id="login-wrapper" className="container-fluid section-container">
        <div className="container">
          <div className="section-heading mb-5 text-center">
            <h1 className="title">
              TradInn empowers high-impact
              <br /> knowledge partners to create risk
              <br /> free investment decisions.
            </h1>
          </div>

          <div className="new-account">
            <img className="logo" src="/images/fav-white.png" />
            <a href="#">Create a New Account</a>
            <span></span>
          </div>
          <Form
            form={form}
            onFinish={onFormSubmit}
            onFinishFailed={onFinishFailed}
            className="login-form"
            autoComplete="off"
          >
            <label>Name</label>
            <Item
              name="FirstName"
              rules={[
                {
                  required: true,
                  message: "Please input your name",
                },
                {
                  min: 4,
                  message: "Name must be min 4 characters.",
                },
              ]}
            >
              <input
                className="input-text"
                type="text"
                name=""
                placeholder="Enter Full Name"
              />
            </Item>
            <label>Enter Email*</label>
            <Item
              name="UserName"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <input
                className="input-text"
                type="text"
                name=""
                placeholder="Enter Email Address"
              />
            </Item>
            <label>Password</label>
            <Item
              name="Password"
              className="local-form input-reg"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
                  message:
                    "Password must be between 8 to 15 characters which should contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character",
                },
              ]}
              hasFeedback
            >
              <input
                className="input-text"
                type="password"
                name=""
                placeholder="Enter Password"
              />
            </Item>
            <label>Confirm Password*</label>
            <Item
              name="Confirm"
              dependencies={["Password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_rule, value) {
                    if (!value || getFieldValue("Password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <input
                maxLength={30}
                type="password"
                className="input-text"
                placeholder="Confirm Password"
                autoComplete="off"
              />
            </Item>

            <label>Select a profile type* </label>
            <br />
            <Item
              name="UserType"
              rules={[
                {
                  required: true,
                  message: "Please pick account type!",
                },
              ]}
              noStyle
            >
              <Radio.Group onChange={(e) => setUserType(e.target.value)}>
                <Radio.Button
                  style={{ color: "black" }}
                  value={UserType.CUSTOMER}
                >
                  CUSTOMER
                </Radio.Button>
                <Radio.Button
                  style={{ color: "black" }}
                  value={UserType.CONSULTANT}
                >
                  CONSULTANT
                </Radio.Button>
              </Radio.Group>
            </Item>

            <div className="checkbox">
              <Item
                name="checkbox"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please accept the terms and conditions!",
                //   },
                // ]}
                // noStyle
              >
                <input type="checkbox" name="" />
              </Item>
              <label>
                Yes, I understand and agree to the TradInn's Privacy Policy and
                Website Disclamer. I also agree that I am signing up for emails
                to be sent to my provided email address for communication and
                acknowledgment purposes.
              </label>
            </div>
            <button
              type="submit"
              disabled={status === "userSignedUpPending"}
              style={{
                width: "100%",
                display: "block",
                padding: "10px",
                cursor: "pointer",
                color: "#FFF",
                background: "#000",
                border: "none",
                borderRadius: "4px",
                transition: "all 300ms ease-in-out",
              }}
            >
              <span id="button-text">
                {status === "userSignedUpPending" ? (
                  <Spin size="small" />
                ) : (
                  "Register"
                )}
              </span>
            </button>
            <label className="d-block mt-5 text-center">
              Already have an account?
              <br />
              <b>
                {" "}
                <a href="/login">Login Now</a>
              </b>
            </label>
          </Form>
        </div>
      </div>
      <ConsultantFooter />
    </>
  );
};
