import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import * as AuthService from "../../../store/auth/auth.actions";
import { resetAuthState } from "../../../store/auth/auth.slice";
import { resetSupplierState } from "../../../store/supplier/supplier.slice";
import { resetInquiryState } from "../../../store/inquiry/inquiry.slice";
import { resetQuoteState } from "../../../store/quotation/quotation.slice";
import { resetListState } from "../../../store/lists/lists.slice";
import { displaySuccessNotification } from "../../../utils/notifications";
import { RootState } from "../../../types";
import { Avatar, Col, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";

const CustomerProfileNav = () => {
  const { user } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onLogOut = () => {
    dispatch(AuthService.logOut());
    dispatch(resetAuthState());
    dispatch(resetSupplierState());
    dispatch(resetInquiryState());
    dispatch(resetQuoteState());
    dispatch(resetListState());
    displaySuccessNotification("You've successfully logged out!");
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light navbar-bg">
        <a className="sidebar-toggle js-sidebar-toggle">
          <i className="hamburger align-self-center"></i>
        </a>

        <div className="navbar-collapse collapse">
          <ul className="navbar-nav navbar-align">
            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="align-middle" data-feather="settings"></i>
              </a>
              <a
                className="nav-link dropdown-toggle d-none d-sm-inline-block"
                href="#"
                data-bs-toggle="dropdown"
              >
                <Row gutter={16}>
                  <Col>
                    <span>{user.firstName}</span>
                  </Col>
                  <Col>
                    <Avatar src={user.image!} icon={<UserOutlined />} />
                  </Col>
                </Row>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="/customer/mainprofile">
                  Profile
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/customer/manageprofile">
                  Settings
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="#" onClick={onLogOut}>
                  Log out
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default CustomerProfileNav;
