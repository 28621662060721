import ConsultantMainFooter from "../MainFooter";
import ConsultantMainNav from "../ProfileNav";
import { ReactNode } from "react";
import CustomerNav from "../MainNav";
import CustomerProfileNav from "../ProfileNav";
import CustomerFooter from "../MainFooter";

const CustomerTemplate = (props: { children: ReactNode }) => {
  return (
    <>
      <div className="wrapper">
        <CustomerNav />

        <div className="main">
          <CustomerProfileNav />
          <main className="content">{props.children}</main>
          <CustomerFooter />
        </div>
      </div>
    </>
  );
};

export default CustomerTemplate;