import { Form, Spin } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useEffectOnce from "../../hooks/useEffectOnce";
import * as AuthService from "../../store/auth/auth.actions";
import { Product } from "../../types";
import userNotOnlineImg from "../../assets/img/user-not-online.jpg";
import * as routes from "../../constants/routes";
import { clearAuthError, clearAuthStatus } from "../../store/auth/auth.slice";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../utils/notifications";
import ConsultantTemplate from "../Consultant/Template";

const { Item } = Form;

// interface ForgetPasswordPageProps {
//   product: ProductResponse;
//   barterProduct: ProductResponse;
// }

export const ConsultantForgetPasswordPage =
  (/*props: ForgetPasswordPageProps*/) => {
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const { status: authStatus, error: authError } = useAppSelector(
      (state) => state.auth
    );

    const dispatch = useAppDispatch();

    const onFormSubmit = (formData: any) => {
      dispatch(AuthService.forgetPassword(formData));
    };

    const onFinishFailed = () => {
      displayErrorMessage("Please complete all required form fields!");
      return;
    };

    useEffect(() => {
      if (authError) {
        displayErrorMessage(authError);
        dispatch(clearAuthError());
      }
    }, [dispatch, authError]);

    useEffect(() => {
      if (authStatus === "forgetPasswordResolved") {
        displaySuccessNotification(
          "Email sent, Please check your inbox for reset password link."
        );
        dispatch(clearAuthStatus());
      }
    }, [dispatch, authStatus]);

    return (
      <>
        <ConsultantTemplate>
          <div className="wrapper">
            <div className="main">
              <main className="content">
                <div className="container-fluid p-0">
                  <h1 className="h3 mb-3">Forget Password? Don't Worry!</h1>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="container">
                            <Form
                              id="regForm"
                              className="user-form"
                              layout="vertical"
                              form={form}
                              onFinish={onFormSubmit}
                              onFinishFailed={onFinishFailed}
                              autoComplete="off"
                            >
                              <h4 className="mt-4">Email Address</h4>
                              <div className="row">
                                <Item name="Token" hidden={true}>
                                  <input></input>
                                </Item>
                                <aside className="col-lg-12">
                                  <Item
                                    label="Enter your e-mail address here"
                                    name="Email"
                                    rules={[
                                      {
                                        type: "email",
                                        message:
                                          "The input is not valid E-mail!",
                                      },
                                      {
                                        required: true,
                                        message: "Please input your E-mail!",
                                      },
                                    ]}
                                  >
                                    <input
                                      maxLength={50}
                                      type="email"
                                      className="input-text"
                                      placeholder={"Email ID"}
                                      autoComplete="off"
                                    />
                                  </Item>
                                </aside>
                              </div>

                              <button
                                type="submit"
                                className="btn-save"
                                disabled={
                                  authStatus === "forgetPasswordPending"
                                }
                              >
                                <span id="button-text">
                                  {authStatus === "forgetPasswordPending" ? (
                                    <Spin size="small" />
                                  ) : (
                                    "Request Reset"
                                  )}
                                </span>
                              </button>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </ConsultantTemplate>
      </>
    );
  };
