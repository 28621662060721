import React from "react";
import { Link } from "react-router-dom";
import ConsultantFooter from "../../Consultant/HomeFooter";
import { useAppSelector, useScrollToTop } from "../../../hooks";
import { UserType } from "../../../enums";
import SiteHeader from "../../Supplier/SiteHeader";

export const Aboutus = () => {
  useScrollToTop();

  const { user } = useAppSelector((state) => state.auth);

  return (
    <>
      <SiteHeader />

      <img className="page-hero" src="/images/about-us.jpg" />

      <div className="container-fluid section-container bg-lightgray text-center">
        <div className="container">
          <div className="section-heading mb-5">
            <h1 className="title">ABOUT US</h1>
          </div>
          <p>
            TradInn online platform is based in London and aims to be the
            perfect meeting place for consultants from all sectors and service
            seekers to connect and collaborate. Our platform caters to
            consultants on TradInn, who can log in and provide their consultancy
            services to service seekers, helping them achieve their project
            goals. We are dedicated to providing a seamless and professional
            experience for all our users and strive to offer the necessary
            resources and support to ensure a successful collaboration.
          </p>
        </div>
      </div>

      <div className="container-fluid text-center">
        <div className="row">
          <aside className="col-lg-6  section-container">
            <div className="container-half mr-0 ml-auto">
              <h1>Mission</h1>
              <br />
              <p>
                Our mission at TRADEINN is to transform how consultants from
                various sectors connect with customers across the UK.Our goal is
                to provide a seamless, efficient, and innovative online platform
                that sets new standards for service delivery.
              </p>

              <p>
                At TRADEINN, we create an ecosystem where consultants and
                customers collaborate to achieve tasks. Our platform breaks down
                barriers, bridges gaps, and fosters meaningful connections,
                resulting in exceptional service outcomes.
              </p>
            </div>
          </aside>
          <aside className="col-lg-6 bg-mission"></aside>
          <aside className="col-lg-6 bg-vision"></aside>
          <aside className="col-lg-6 section-container">
            <div className="container-half mr-auto ml-4">
              <h1>Vision</h1>
              <br />
              <p>
                At TRADINN, we aim to be the ultimate platform for consultants
                to connect with customers and offer their services. We strive to
                revolutionise the consulting marketplace, setting new standards
                of excellence. We focus on creating an open and dynamic
                ecosystem where consultants can display their expertise and
                customers can access high-quality services that meet their
                needs. We strive to foster trust, quality, and specialisation
                through our platform, and we are committed to continuously
                adapting and evolving to meet the changing needs of our users.
                We want TRADINN to become the go-to platform for consulting
                services in the UK.
              </p>
            </div>
          </aside>
        </div>
      </div>

      <div className="container-fluid section-container bg-lightgray text-center">
        <div className="container">
          <div className="section-heading mb-5">
            <h1 className="title">Company Values</h1>
          </div>
          <p>
            At TRADEINN, our core values drive excellence and guide every aspect
            of our operation. At TRADEINN, we prioritise the following values:At
            TRADEINN, we empower consultants and customers through our platform.
            Consultants can highlight their expertise and connect with
            customers, while customers can get their work done hassle-free.
            TRADEINN takes great pride in maintaining the highest quality
            standards for our users. We connect you with consultants committed
            to providing exceptional value while ensuring your project is
            executed flawlessly. We are always dedicated to meeting the evolving
            needs of our users consistently. We organise our information
            logically and prioritise the most important details, ensuring your
            journey at TRADEINN is direct and easy to follow.
          </p>
        </div>
      </div>
      <ConsultantFooter />
    </>
  );
};
