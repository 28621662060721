import ConsultantMainFooter from "../MainFooter";
import ConsultantMainNav from "../MainNav";
import ConsultantProfileNav from "../ProfileNav";
import { ReactNode } from "react";

const ConsultantTemplate = (props: { children: ReactNode }) => {
  return (
    <>
      <div className="wrapper">
        <ConsultantMainNav />

        <div className="main">
          <ConsultantProfileNav />
          <main className="content">{props.children}</main>
          <ConsultantMainFooter />
        </div>
      </div>
    </>
  );
};

export default ConsultantTemplate;