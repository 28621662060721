import {Form, notification, Spin} from "antd";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import * as AuthService from "../../../store/auth/auth.actions";
import {
    clearAuthError,
    clearAuthStatus,
} from "../../../store/auth/auth.slice";
import {
    displayErrorMessage,
    displayLoadingMessage,
    displaySuccessNotification,
} from "../../../utils/notifications";
import userNotOnlineImg from "../../assets/img/user-not-online.png";
import {PreLoader} from "../loader";

const {Item} = Form;

export const SendEmailNotification = () => {
    const {user} = useAppSelector((state) => state.auth);

    const [form] = Form.useForm();

    const dispatch = useAppDispatch();

    const {status: authStatus, error: authError} = useAppSelector(
        (state) => state.auth
    );

    useEffect(() => {
        if (authError) {
            displayErrorMessage(authError);
            dispatch(clearAuthError());
        }
    }, [authError]);

    useEffect(() => {
        if (authStatus === "sendEmailResolved") {
            displaySuccessNotification("Email has been sent");
        }

        if (authStatus.includes("Pending")) {
            displayLoadingMessage(<PreLoader/>);
        } else {
            notification.close("loading");
        }

        return () => {
            dispatch(clearAuthStatus());
        };
    }, [authStatus]);

    const onFormSubmit = (formData: any) => {
        formData.userId = user.id;

        dispatch(AuthService.sendNotification(formData));
    };

    const onFinishFailed = () => {
        displayErrorMessage("Please complete all required form fields!");
        return;
    };

    return (
        <>
            <Form
                form={form}
                onFinish={onFormSubmit}
                onFinishFailed={onFinishFailed}
                id="regForm"
                className="user-form"
            >
                <div className="container text-center">
                    <div className="row">
                        <div className="col-lg-12">
                            <img src={userNotOnlineImg} alt=""/>
                        </div>

                        <div className="col-lg-12">
                            <Item
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter details to notify user",
                                    },
                                ]}
                            >
                  <textarea
                      className="textarea"
                      placeholder="Write your message here"
                  ></textarea>
                            </Item>
                        </div>

                        <div className="col-lg-12">
                            <button
                                className="btn-save"
                                style={{display: 'block', margin:  'auto'}}
                                type="submit"
                                disabled={authStatus === "sendEmailPending"}
                            >
                              <span id="button-text">
                                {authStatus === "sendEmailPending" ? (
                                    <Spin size="small"/>
                                ) : (
                                    "Send Email"
                                )}
                              </span>
                            </button>
                        </div>
                    </div>

                </div>
            </Form>
        </>
    );
};
