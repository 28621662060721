import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InquiryResponse } from "../../../types";
import * as InquiryService from "../../../store/inquiry/inquiry.actions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import CustomerNav from "../MainNav";
import CustomerTemplate from "../Template";
import moment from "moment";
import { Space, Tooltip } from "antd";
import { WechatOutlined } from "@ant-design/icons";
import * as routes from "../../../constants/routes";

export const CustomerInquiryListPage = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { inquiries } = useAppSelector((state) => state.inquiry);

  React.useEffect(() => {
    dispatch(InquiryService.fetchUserInquiries(0));
  }, []);

  return (
    <>
      <CustomerTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Inquires List</h1>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th scope="col">S.No </th>
                          <th scope="col">Submission Date</th>
                          <th scope="col">Project Name</th>
                          <th scope="col">Consultant Name</th>
                          <th scope="col">Inquiry Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inquiries.map((inq, index) => (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>
                              {moment(inq.startDate).format("MM/DD/yyyy")} -
                              {moment(inq.endDate).format("MM/DD/yyyy")}
                            </td>
                            <td>{inq.title}</td>
                            <td>
                              {inq?.inquiryConsultant?.map((inc) => (
                                <Space direction="vertical">
                                  {inc.applicationUser?.firstName +
                                    " " +
                                    inc.applicationUser?.lastName}
                                </Space>
                              ))}
                            </td>
                              {<td>
                              {inq.status === 1
                                ? "Posted"
                                : inq.status === 3
                                ? "Declined"
                                : inq.status === 2
                                ? "Accpeted"
                                : ""}
                            </td> }
                            <td>
                              <button
                                className="btn-view"
                                // to={routes.TO_CHAT_APP}
                                // state={{ inquiry: inq }}
                                onClick={() =>
                                  navigate("/customer/inquiryview", {
                                    state: { inquiry: inq },
                                  })
                                }
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomerTemplate>
    </>
  );
};
