import { Col, Form, notification, Row, Spin, Table } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import * as routes from "../../../constants/routes";
import { SubscriptionData, SubscriptionResponse } from "../../../types";
import * as SetupService from "../../../store/setups/setups.actions";
import { useEffect } from "react";
import { PreLoader } from "../../Components";
import { clearSetupStatus } from "../../../store/setups/setups.slice";
import useEffectOnce from "../../../hooks/useEffectOnce";

const { Item } = Form;

const gridColumns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "Id",
    render: (text: string, record: any) => (
      <Link
        to={`/admin/subscription/edit/${record.id}`}
        state={{ subscription: record }}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "Title",
  },
  {
    title: "Charges",
    dataIndex: "charges",
    key: "Charges",
  },
];

export const AdminSubscriptionPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const {
    setups,
    status: setupStatus,
    error: setupError,
  } = useAppSelector((state) => state.setup);

  const onSubscriptionFormSubmit = (values: SubscriptionData) => {
    if (location.pathname.includes("/subscription/edit/")) {
      dispatch(SetupService.updateSubscription(values));
    } else {
      dispatch(SetupService.createSubscription(values));
    }
  };

  const onSubscriptionFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (id && String(id).length > 0) {
      let selectedSubscription: SubscriptionResponse | undefined =
        setups.subscriptions?.find((a) => String(a.id) === String(id));

      if (selectedSubscription) {
        form.setFieldsValue({
          Id: selectedSubscription.id,
          Title: selectedSubscription.title,
          Days: selectedSubscription.days,
          Charges: selectedSubscription.charges,
        });
      }
    }
  }, [id]);

  useEffect(() => {
    if (setupStatus === "createSubscriptionResolved") {
      displaySuccessNotification("Subscription has been created");

      dispatch(SetupService.fetchSubscriptions({ pageNo: 1, pageSize: 10 }));

      dispatch(clearSetupStatus());
    }
    if (setupStatus === "updateSubscriptionResolved") {
      displaySuccessNotification("Subscription has been updated");

      dispatch(SetupService.fetchSubscriptions({ pageNo: 1, pageSize: 10 }));

      dispatch(clearSetupStatus());
    }

    if (setupStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
    // dispatch(clearSetupStatus());
  }, [dispatch, setupStatus]);

  const navigateAndNew = () => {
    form.setFieldsValue({
      Id: 0,
      Title: "",
      Days: 0,
      Charges: 0,
    });

    navigate(routes.ADMIN_DASHBOARD);
  };

  useEffectOnce(() => {
    dispatch(SetupService.fetchSubscriptions({ pageNo: 1, pageSize: 10 }));
  });

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubscriptionFormSubmit}
        onFinishFailed={onSubscriptionFinishFailed}
        size="large"
        id="regForm"
        className="user-form"
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>Subscription Setup</h3>
            <br />
            <p>
              Please Fill all the field to complete information to setup
              subscription model
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Row gutter={16}>
              <Col span={12}>
                <Table
                  columns={gridColumns}
                  dataSource={setups.subscriptions}
                  pagination={{
                    total: setups.subscriptions.length,
                    pageSize: 10,
                  }}
                />
              </Col>
              <Col span={12}>
                <div className="row">
                  <div className="col-lg-12">
                    <Item name="Id" hidden={true}>
                      <input />
                    </Item>
                    <Item
                      name="Title"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter the title/name of the subscription!",
                        },
                      ]}
                    >
                      <input
                        maxLength={30}
                        className="input-text"
                        placeholder="Subscription title"
                      ></input>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <Item
                      name="Days"
                      rules={[
                        {
                          required: true,
                          message: "Please the subscription period!",
                        },
                      ]}
                    >
                      <select className="form-select">
                        <option value="0">Select Period</option>
                        <option value="30">Monthly</option>
                        <option value="90">Quarterly</option>
                        <option value="180">Half yearly</option>
                        <option value="360">Yearly</option>
                      </select>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <Item
                      name="Charges"
                      rules={[
                        {
                          required: true,
                          message: "Please charges for the period!",
                        },
                      ]}
                    >
                      <input
                        type="number"
                        min={0}
                        max={999}
                        step="any"
                        className="input-text"
                        placeholder="Charges"
                      ></input>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <button
                      type="button"
                      className="btn-save"
                      onClick={navigateAndNew}
                    >
                      New
                    </button>
                  </div>
                  <div className="col-lg-3">
                    <button
                      type="submit"
                      className="btn-save"
                      disabled={setupStatus === "createCityPending"}
                    >
                      <span id="button-text">
                        {setupStatus === "createCityPending" ? (
                          <Spin size="small" />
                        ) : (
                          "Save"
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-3"></div>
                  <div className="col-lg-3"></div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};
