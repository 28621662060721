import { Link } from "react-router-dom";

const CustomerMainNav = () => {
  return (
    <>
      <nav id="sidebar" className="sidebar js-sidebar">
        <div className="sidebar-content js-simplebar">
          <Link className="sidebar-brand" to="/customer/home">
            <span className="align-middle">
              <img className="logo" src="/images/tradin.png" />
            </span>
          </Link>
          <ul className="sidebar-nav">
            <li className="sidebar-header">
              <Link to="/customer/home">
                <i className="fas fa-home"></i> Home
              </Link>
            </li>

            <li className="sidebar-header">
              <i className="fas fa-tachometer-alt"></i> Customer Dashboard
              <Link className="sidebar-link" to="/customer/mainprofile">
                Your Profile
              </Link>
            </li>
            <li className="sidebar-header">
              <i className="fas fa-file-alt"></i> Inquiry
              <Link className="sidebar-link" to="/customer/createinquiry">
                Create New Inquiry
              </Link>
              <Link className="sidebar-link" to="/customer/inquirylist">
                Inquiry Log
              </Link>
            </li>
            <li className="sidebar-header">
              <i className="fas fa-user-tie"></i> Consultants
              <Link className="sidebar-link" to={"/consultant/list"}>
                Explore consultants
              </Link>
            </li>
            <li className="sidebar-header">
              <i className="fas fa-user-edit"></i> Manage ID
              <Link className="sidebar-link" to="/customer/manageprofile">
                Manage Profile
              </Link>
              <Link className="sidebar-link" to="/customer/forgotpwd">
                Password Reset
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default CustomerMainNav;
