import { Form, Input, notification, Spin } from "antd";
import { PreLoader, UploadPictureWall } from "../../Components";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import { useEffect } from "react";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { CreateSupplierProductData, Product } from "../../../types";
import * as SupplierService from "../../../store/supplier/supplier.action";
import { CategoryWidget } from "../../Components";
import {
  clearSupplierStatus,
  clearSupplierError,
} from "../../../store/supplier/supplier.slice";
import TextEditor from "../../Components/textEditor";
import { useLocation } from "react-router-dom";

const { Item } = Form;

interface AddServicePageProps {
  categoryIndex: number;
}

export const AddServicePage = (props: AddServicePageProps) => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [form] = Form.useForm();

  const { status, error } = useAppSelector((state) => state.supplier);

  const onServiceFormSubmit = (values: CreateSupplierProductData) => {
    if (location.pathname.endsWith("/product/service/edit")) {
      dispatch(SupplierService.updateProduct(values));
    } else {
      dispatch(SupplierService.createProduct(values));
    }
  };

  const { product } = (location.state as any) || {};

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);
      dispatch(clearSupplierError());
    }
    if (status === "createProductSuccess") {
      displaySuccessNotification("New service has been added");

      form.resetFields();

      dispatch(clearSupplierStatus());
    }

    if (status === "updateProductSuccess") {
      displaySuccessNotification("Service has been updated");

      form.resetFields();

      dispatch(clearSupplierStatus());
    }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      // notification.close("loading");
    }
  }, [dispatch, status, error]);

  const onServiceFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        Id: product.id,
        EditCategoryId: product.category.categoryId,
        Title: product.title,
        SubTitle: product.subTitle,
        Description: product.description,
        Specification: product.specification,
        Features: product.features,
      });
    }
  }, [location, product]);

  useScrollToTop();

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onServiceFormSubmit}
        onFinishFailed={onServiceFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>Your Services</h3>
            <br />
            <p>
              Please Fill all the field to complete information about the
              services you offer.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            {/* CATEGORY INPUT */}
            {location.pathname.endsWith("add") && (
              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6" style={{ textAlign: "center" }}>
                  <CategoryWidget
                    name={"CategoryId"}
                    categoryIndex={1}
                    placeHolder="Select the service category"
                    allowMultiple={false}
                    allowChangeOnSelect={false}
                  ></CategoryWidget>
                </div>
                <div className="col-lg-3"></div>
              </div>
            )}
            {/* TITLE SUB TITLE INPUT */}
            <div className="row">
              <div className="col-lg-6">
                <Item name="Id" hidden={true}>
                  <input />
                </Item>
                <Item name="EditCategoryId" hidden={true}>
                  <input />
                </Item>
                <Item
                  noStyle
                  name="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter what defines your service title!",
                    },
                  ]}
                >
                  <input
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Please enter what defines your service title!"
                  />
                </Item>
              </div>

              <div className="col-lg-6">
                <Item
                  noStyle
                  name="SubTitle"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your service subtitle!",
                    },
                  ]}
                >
                  <input
                    maxLength={50}
                    className="local-form input-reg"
                    placeholder="Please enter what defines your service subtitle!"
                  />
                </Item>
              </div>
            </div>

            <div className="row">
              <br />
            </div>

            {/* DESCRIPTION SPECS INPUT */}
            <div className="row">
              <div className="col-lg-6">
                <Item
                  noStyle
                  name="Description"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your service description!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        if (!value || !value.includes("<script>")) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Invalid input found!");
                      },
                    }),
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={100}
                    placeholder="Description"
                  />
                </Item>
              </div>

              <div className="col-lg-6">
                <Item
                  noStyle
                  name="Specification"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your service specifications!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        if (!value || !value.includes("<script>")) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Invalid input found!");
                      },
                    }),
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={100}
                    placeholder="Specifications"
                  />
                </Item>
              </div>
            </div>

            {/* DESCRIPTION SPECS INPUT */}
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6" style={{ textAlign: "center" }}>
                <UploadPictureWall
                  ItemName="ImageFiles"
                  buttonText="Service Images"
                  type="media"
                ></UploadPictureWall>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <button
                type="submit"
                className="butn butn-block"
                disabled={status === "createProductPending"}
              >
                <span id="button-text">
                  {status === "createProductPending" ? (
                    <Spin size="small" />
                  ) : (
                    "Save"
                  )}
                </span>
              </button>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </Form>
    </>
  );
};
