import React from "react";
import { Link } from "react-router-dom";
import CustomerTemplate from "../Template";
import { useScrollToTop } from "../../../hooks";

export const CustomerHomePage = () => {
  useScrollToTop();

  return (
    <>
      <CustomerTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Welcome to Tradinn! </h1>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <aside className="col-lg-4">
                  <div className="profile-step">
                    <span>
                      <h3>Step 01</h3>
                      <img src="/images/step1.svg" />
                    </span>
                    <p>To get started, please create your profile.</p>
                  </div>
                </aside>
                <aside className="col-lg-4">
                  <div className="profile-step">
                    <span>
                      <h3>Step 02</h3>
                      <img src="/images/step2.svg" />
                    </span>
                    <p>Explore Consultants.</p>
                  </div>
                </aside>
                <aside className="col-lg-4">
                  <div className="profile-step">
                    <span>
                      <h3>Step 03</h3>
                      <img src="/images/step3.svg" />
                    </span>
                    <p>Create Inquiry and connect with the consultant.</p>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </CustomerTemplate>
    </>
  );
};
