import { createAsyncThunk } from "@reduxjs/toolkit";
import { onValue, ref } from "firebase/database";
import config from "../../config";
import {
  CreateAccountData,
  LoginData,
  Profile,
  User,
  ProfileResponse,
  PaymentResponse,
  CompanyResponse,
  // LoginResponse,
  // Profile,
  // User,
} from "../../types";
import http from "../../utils/http";
import * as helper from "../../utils/helper";
import { async } from "@firebase/util";

export const createAccount = createAsyncThunk(
  "auth/register",
  async (
    {
      CompanyName,
      FullName,
      FirstName,
      UserName,
      Password,
      Address,
      Longitude,
      Latitude,
      UserType,
    }: CreateAccountData,
    thunkAPI
  ) => {
    try {
      const url = config.endpoints.auth.createAccount;
      const {
        data: { user },
      } = await http.post<{ user: CreateAccountData }>(url, {
        CompanyName,
        FullName,
        FirstName,
        UserName,
        Password,
        Address,
        Longitude,
        Latitude,
        UserType,
      });
      return user;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const logIn = createAsyncThunk(
  "auth/logIn",
  async ({ UserName, Password }: LoginData, thunkAPI) => {
    try {
      const url = config.endpoints.auth.login;
      const {
        data: { result },
      } = await http.post<{ result: ProfileResponse }>(url, {
        UserName,
        Password,
      });
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const activateAccount = createAsyncThunk(
  "auth/activate",
  async (values: { token: string; email: string }, thunkAPI) => {
    try {
      const url = config.endpoints.auth.activateAccount;

      const {
        data: { result },
      } = await http.post<{ result: {} }>(url, values);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const logOut = createAsyncThunk("auth/logOut", async (_, thunkAPI) => {
  try {
    const url = config.endpoints.auth.logout;
    await http.post(url);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const loadCurrentUser = createAsyncThunk(
  "auth/loadCurrentUser",
  async (_, thunkAPI) => {
    try {
      const url = config.endpoints.auth.profile;
      const {
        data: { result },
      } = await http.get<{ result: ProfileResponse }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const loadCurrentProfile = createAsyncThunk(
  "auth/loadCurrentProfile",
  async (_, thunkAPI) => {
    try {
      const url = config.endpoints.auth.profile;
      const {
        data: { result },
      } = await http.get<{ result: CompanyResponse }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createProfile = createAsyncThunk(
  "profile/createProfile",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.profile.createProfile;

      // let profileObject: Profile = {};
      // profileObject.company = {};

      // profileObject.image = values.Image;
      // profileObject.fullName = values.FullName;
      // profileObject.phoneNumber = values.PhoneNumber;
      // profileObject.mobileNumber = values.MobileNumber;
      // profileObject.contactPersonFirstName = values.ContactPersonFirstName;
      // profileObject.contactPersonLastName = values.ContactPersonLastName;
      // profileObject.contactPersonJobTitle = values.ContactPersonJobTitle;
      // profileObject.address = values.Address;
      // profileObject.longitude = values.Longitude;
      // profileObject.latitude = values.Latitude;
      // profileObject.cityId = values.CityId;
      // profileObject.countryId = values.CountryId;
      // profileObject.postalCode = values.PostalCode;
      // profileObject.company.image = values["CompanyLogo"];
      // profileObject.company.banner = values["CompanyBanner"];
      // profileObject.company.companyName = values.CompanyName;
      // profileObject.company.companyDetails = values.CompanyDetails;
      // profileObject.company.companyOverview = values.CompanyOverview;
      // profileObject.company.governmentIdentifier = values.GovernmentIdentifier;
      // profileObject.company.companyRegistrationNumber =
      //   values.CompanyRegistrationNumber;
      // profileObject.company.licenseCertificateNumber =
      //   values.LicenseCertificateNumber;
      // profileObject.company.vatRegistrationNumber =
      //   values.VatRegistrationNumber;
      // profileObject.company.website = values.Website;
      // profileObject.company.mainAddress = values.MainAddress;
      // profileObject.company.areaCoverage = values.AreaCoverage;

      // profileObject.company.media = [];
      // if (values.Media) {
      //   for (let index = 0; index < values.Media["fileList"].length; index++) {
      //     profileObject.company.media.push(
      //       values.Media["fileList"][index].response.result
      //     );
      //   }
      // }

      // if (values.SocialLinksList) {
      //   profileObject.company.socialLinks = JSON.stringify(
      //     values.SocialLinksList
      //   );
      // } else {
      //   profileObject.company.socialLinks = values.SocialLinks;
      // }

      // profileObject.company.videoLink = [];
      // if (values.VideoLinks) {
      //   values.VideoLinks.forEach((vid: any) => {
      //     profileObject.company?.videoLink?.push(vid["Link"]);
      //   });
      // }

      const {
        data: { profile },
      } = await http.post<{ profile: Profile }>(url, values);
      return profile;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (_, thunkAPI) => {
    try {
      const url = `${config.endpoints.profile.fetchProfile}`;
      const { data } = await http.get<Profile>(url);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const sendNotification = createAsyncThunk(
  "deal/sendNotification",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.auth.sendNotify}`;

      const {
        data: { result },
      } = await http.post<{ result: boolean }>(url, values);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const initPaymentIntent = createAsyncThunk(
  "user/initPaymentIntent",
  async (id: number, thunkAPI) => {
    try {
      const url = `${config.endpoints.auth.initPay}/${id}`;

      const {
        data: { result },
      } = await http.get<{ result: PaymentResponse }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const confirmPaymentIntent = createAsyncThunk(
  "user/confirmPaymentIntent",
  async (_, thunkAPI) => {
    try {
      const url = config.endpoints.auth.updPay;

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.auth.forgotpassword}`;

      const {
        data: { result },
      } = await http.post<{ result: boolean }>(url, values);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.auth.resetPassword}`;

      const {
        data: { result },
      } = await http.post<{ result: boolean }>(url, values);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
