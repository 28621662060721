import React from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../Supplier/SiteHeader";
import { useScrollToTop } from "../../../hooks";

export const Contactus = () => {
  useScrollToTop();

  return (
    <>
      <SiteHeader />

      <img className="page-hero" src="/images/about-us.jpg" alt="About Us" />

      <div id="" className="container-fluid">
        <div className="row">
          <aside className="col-lg-6">
            <div className="container-half section-container mr-0 ml-auto">
              <div className="section-heading mb-5">
                <h1 className="title">CONTACT US</h1>
              </div>

              <p>
                <b>UNITED KINGDOM</b>
              </p>
              <p>
                Address: 408 West Green Road, London, England, N15 3PX
                <br />
                Telephone: +44 20 8144 6692
                <br />
                Email: info@intellectworks.co.uk
              </p>
            </div>
          </aside>
          <aside className="col-lg-6">
            <p>
              <b>Location:</b> London, UK
            </p>
            <p>
              View on{" "}
              <a
                href="https://www.google.com/maps/place/London,+UK"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Maps
              </a>
            </p>
          </aside>
        </div>
      </div>

      <footer>
        <div className="container">
          <div className="row mb-5 text-center text-lg-left">
            <div className="col-lg-4">
              <img src="/images/tradin.png" />
            </div>
            <div className="col-lg-2">
              <Link to="/">Home</Link>
              <Link to="/consultant/list">Consultant</Link>
              <Link to="/infohub">Info Hub</Link>
              <Link to="/aboutus">About Us</Link>
            </div>
            <div className="col-lg-2">
              <p>
                408 West Green Road, London. N15 3PX
                <br />
                +44 7399 314957
                <br />
                support@tradinn.org.uk
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4">
              <div className="social-icons">
                <img src="/images/in.png" />
                <img src="/images/tw.png" />
                <img src="/images/fb.png" />
              </div>
            </div>
            <div className="col-lg-6">
              <p className="text-right">
                Copyrights © 2024 | All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
