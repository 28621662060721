import { Link } from "react-router-dom";

const ConsultantFooter = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row mb-5 text-center text-lg-left">
            <div className="col-lg-4">
              <img src="/images/tradin.png" />
            </div>
            <div className="col-lg-2">
              <Link to="/">Home</Link>
              <Link to="/consultant/list">Consultant</Link>
              <Link to="/infohub">Info Hub</Link>
              <Link to="/aboutus">About Us</Link>
            </div>
            <div className="col-lg-2">
              <p>408 West Green Road, London. N15 3PX<br/>
              +44 7399 314957<br/>
              support@tradinn.org.uk</p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4">
              <div className="social-icons">
                <img src="/images/in.png" />
                <img src="/images/tw.png" />
                <img src="/images/fb.png" />
              </div>
            </div>
            <div className="col-lg-6">
              <p className="text-right">
                Copyrights © 2024 | All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ConsultantFooter;
