const ConsultantMainFooter = () => {
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <p className="mb-0">
                <a className="text-muted" href="/home" target="_blank">
                  <strong>TradInn Ltd.</strong>
                </a>
              </p>
            </div>
            <div className="col-6 text-end">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a className="text-muted" href="#" target="_blank">
                  Copyrights © 2024 | All Rights Reserved
                  </a>
                </li>
                {/* <li className="list-inline-item">
                  <a className="text-muted" href="#" target="_blank">
                    Help Center
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#" target="_blank">
                    Privacy
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#" target="_blank">
                    Terms
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ConsultantMainFooter;
