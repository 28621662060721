import { Link } from "react-router-dom";

const AdminNav = () => {
  return (
    <>
      <nav id="sidebar" className="sidebar js-sidebar">
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="index.html">
            <span className="align-middle">
              <img className="logo" src="/images/tradin.png" />
            </span>
          </a>
          <ul className="sidebar-nav">
            <li className="sidebar-header">
              <i className="fas fa-home"></i> Home
            </li>

            <li className="sidebar-header">
              <i className="fas fa-tachometer-alt"></i> Dashboard
              <a className="sidebar-link" href="customer-list.html">
                Customer
              </a>
              <a className="sidebar-link" href="consultant-list.html">
                Consultant
              </a>
            </li>

            <li className="sidebar-header">
              <i className="fas fa-file-alt"></i> Inquiry
              <a className="sidebar-link" href="inquires-list.html">
                Inquiry List
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default AdminNav;
