import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { Space, Spin, Tooltip } from "antd";
import * as routes from "../../../../constants/routes";
import config from "../../../../config";
import { WechatOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "../../../../hooks";
import { Fragment, useEffect } from "react";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../../../utils/notifications";
import * as InquiryService from "../../../../store/inquiry/inquiry.actions";

const AdminInquiryViewPage = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.auth);

  const location = useLocation();

  const { inquiry, status: inquiryStatus } = (location.state as any) || {};

  const handleChange = (pstatus: number) => {
    debugger
    inquiry.status = pstatus;
  };

  const updateInquiry = () => {
    if (inquiry) {
      dispatch(
        InquiryService.updateInquiryStatus({
          inquiryid: inquiry.id,
          status: inquiry.status,
        })
      );
    }
  };

  return (
    <>
      {inquiry ? (
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Inquiry View </h1>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <h4>Project Info</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <b>Project Title</b>
                            </td>
                            <td>{inquiry?.title}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Industry focus</b>
                            </td>
                            <td>{inquiry?.industry?.title}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Specification of functional focus</b>
                            </td>
                            <td>{inquiry?.specification}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Project description</b>
                            </td>
                            <td>{inquiry?.description}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Attached File</b>
                            </td>
                            <td>
                              {
                                <Space direction="vertical">
                                  {inquiry?.images?.forEach((img: any) => {
                                    <Link
                                      to={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${img.fileName}&type=inquiry`}
                                    >
                                      {img.fileName}
                                    </Link>;
                                  })}
                                </Space>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h4>Consultant requirements</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <tbody>
                          {inquiry.inquiryConsultant?.map(
                            (cons: any, index: number) => (
                              <Fragment key={index.toString()}>
                                <tr>
                                  <td>
                                    <b>Name</b>
                                  </td>
                                  <td>{cons.applicationUser?.firstName}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Industry</b>
                                  </td>
                                  <td>
                                    {cons.applicationUser?.industry?.title}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Required working experience</b>
                                  </td>
                                  <td>{cons.applicationUser?.companyName}</td>
                                </tr>
                              </Fragment>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>

                    <h4 className="mt-5">Project Framework</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <b>Project Period</b>
                            </td>
                            <td>{`${moment(inquiry?.startDate).format(
                              "DD-MM-YYYY"
                            )} - ${moment(inquiry?.endDate).format(
                              "DD-MM-YYYY"
                            )} `}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Project Budget</b>
                            </td>
                            <td>{inquiry?.budget}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Mark Status</b>
                            </td>
                            <td>
                              <select
                                className="form-select"
                                onChange={(e) =>
                                  handleChange(Number(e.target.value))
                                }
                              >
                                <option value="" disabled selected hidden>
                                  Choose Status
                                </option>
                                <option
                                  value="2"
                                  selected={inquiry.status === 2}
                                >
                                  Accepted
                                </option>
                                <option
                                  value="3"
                                  selected={inquiry.status === 3}
                                >
                                  Completed
                                </option>
                                <option
                                  value="4"
                                  selected={inquiry.status === 4}
                                >
                                  Declined
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <Link
                                className="btn-view"
                                to="#"
                                onClick={() => updateInquiry()}
                              >
                                <span id="button-text">
                                  {inquiryStatus === "updateInquiryPending" ? (
                                    <Spin size="small" />
                                  ) : (
                                    "Update"
                                  )}
                                </span>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminInquiryViewPage;
