import { collection, getDocs, query, where } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { useFireBase } from "./firebase/config";
import * as helper from "../../../utils/helper";
import { UserType } from "../../../enums";
import { Avatar, Form, Input, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import AvatarImg from "../../../view/assets/img/avatar.png";

const { Item } = Form;
const { Search } = Input;

interface ChatListProps {
  setRoomIdFromChatList: (roomId: string) => void;
  setConsultantFromChatList: (consultant: any) => void;
}

export const ChatList = (props: ChatListProps) => {
  const location = useLocation();

  const { user } = useAppSelector((state) => state.auth);

  const [chatItems, setChatItems] = useState<any>([]);

  const [auth, fs] = useFireBase();

  const { inquiry, consultant } = (location.state as any) || {};

  const getChatListData = async (items: any[]) => {
    setChatItems(items);
  };

  useEffect(() => {
    const fetchChatList = async () => {
      const q = query(
        collection(fs, "Chats"),
        where("inquiryID", "==", inquiry.id)
      );

      const querySnapshot = await getDocs(q);

      const items = querySnapshot.docs.map((doc) => doc.data());

      getChatListData(items);
    };

    fetchChatList();
  }, [inquiry]);

  const handleLoadChats = (item: any) => {
    let roomId = helper.getEncodedString(
      String(item.customerId),
      String(item.inquiryID),
      String(item.consultantId)
    );

    props.setRoomIdFromChatList(roomId);

    if (user.userType === UserType.CUSTOMER) {
      props.setConsultantFromChatList(item.consultantUserName);
    } else if (user.userType === UserType.CONSULTANT) {
      props.setConsultantFromChatList(item.customerUserName);
    }
  };

  return (
    <>
      <div id="plist" className="people-list">
        <Search
          placeholder="Search..."
          style={{
            border: "1px solid lightgrey",
          }}
        />

        <ul className="list-unstyled chat-list mt-2 mb-0">
          {!!chatItems &&
            chatItems.map((item: any, index: number) => {
              return (
                !!user && (
                  <li
                    className="clearfix"
                    key={index.toString()}
                    onClick={() => handleLoadChats(item)}
                  >
                    <Space direction="horizontal">
                      <img src={AvatarImg} alt="avatar" />

                      <div className="about">
                        <div className="name">
                          {user.userType === UserType.CUSTOMER
                            ? `${item.consultantUserName}`
                            : `${item.customerUserName}`}
                        </div>
                        {/* <div className="status">
                        <i className="fa fa-circle online"></i> online{" "}
                      </div> */}
                      </div>
                    </Space>
                  </li>
                )
              );
            })}
          {/*<li className="clearfix active">*/}
          {/*  <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="avatar"/>*/}
          {/*  <div className="about">*/}
          {/*    <div className="name">Aiden Chavez</div>*/}
          {/*    <div className="status"><i className="fa fa-circle online"></i> online</div>*/}
          {/*  </div>*/}
          {/*</li>*/}
          {/*<li className="clearfix">*/}
          {/*  <img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="avatar"/>*/}
          {/*  <div className="about">*/}
          {/*    <div className="name">Mike Thomas</div>*/}
          {/*    <div className="status"><i className="fa fa-circle online"></i> online</div>*/}
          {/*  </div>*/}
          {/*</li>*/}
          {/*<li className="clearfix">*/}
          {/*  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar"/>*/}
          {/*  <div className="about">*/}
          {/*    <div className="name">Christian Kelly</div>*/}
          {/*    <div className="status"><i className="fa fa-circle offline"></i> left 10*/}
          {/*      hours ago*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</li>*/}
          {/*<li className="clearfix">*/}
          {/*  <img src="https://bootdey.com/img/Content/avatar/avatar8.png" alt="avatar"/>*/}
          {/*  <div className="about">*/}
          {/*    <div className="name">Monica Ward</div>*/}
          {/*    <div className="status"><i className="fa fa-circle online"></i> online</div>*/}
          {/*  </div>*/}
          {/*</li>*/}
          {/*<li className="clearfix">*/}
          {/*  <img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="avatar"/>*/}
          {/*  <div className="about">*/}
          {/*    <div className="name">Dean Henry</div>*/}
          {/*    <div className="status"><i className="fa fa-circle offline"></i> offline*/}
          {/*      since Oct 28*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</li>*/}
        </ul>
      </div>
      {/*<div className="msgs-list mb30">*/}
      {/*    <div className="messages-list scrollstyle_4">*/}
      {/*        <ul>*/}
      {/*            {!!chatItems &&*/}
      {/*                chatItems.map((item: any) => {*/}
      {/*                    return (*/}
      {/*                        !!user && (*/}
      {/*                            <li*/}
      {/*                                key={Math.random()}*/}
      {/*                                className="active"*/}
      {/*                                onClick={() => handleLoadChats(item)}*/}
      {/*                            >*/}
      {/*                                <div className="usr-msg-details">*/}
      {/*                                    <div className="usr-ms-img">*/}
      {/*                                        <Avatar icon={<UserOutlined/>} size="small"/>*/}
      {/*                                    </div>*/}
      {/*                                    <div className="usr-mg-info">*/}
      {/*                                        <h3>{item.inquiryID}</h3>*/}
      {/*                                        <p>*/}
      {/*                                            {user.userType === UserType.CUSTOMER*/}
      {/*                                                ? `${item.customerUserName}`*/}
      {/*                                                : `${item.consultantUserName}`}*/}
      {/*                                        </p>*/}
      {/*                                    </div>*/}
      {/*                                </div>*/}
      {/*                            </li>*/}
      {/*                        )*/}
      {/*                    );*/}
      {/*                })}*/}
      {/*        </ul>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </>
  );
};
