import React from "react";
import { Link } from "react-router-dom";
import CustomerTemplate from "../Template";
import { useAppSelector } from "../../../hooks";

export const CustomerProfilePage = () => {
  const { status, error, user } = useAppSelector((state) => state.auth);

  return (
    <>
      <CustomerTemplate>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-view">
                    <h4>Personal Information</h4>
                    <ul>
                      <li>
                        {user?.firstName} {user?.lastName}
                      </li>
                      {/* <li>Chief Deveops Engineer</li> */}
                      <li>{user?.email}</li>
                      <li>{user?.phoneNumber}</li>
                    </ul>
                  </div>
                  <div className="profile-view">
                    <h4>Company Info</h4>
                    <ul>
                      <li>{user?.companyName}</li>

                      <li>{user?.address}</li>

                      <li>{user?.country?.title}</li>
                    </ul>
                  </div>
                  <div className="profile-view">
                    <h4>Area of Focus</h4>
                    <ul className="areafocus">
                      <ul className="areafocus">
                        {user?.areaOfFocus?.map((ua) => (
                          <li>{ua.title}</li>
                        ))}
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomerTemplate>
    </>
  );
};
