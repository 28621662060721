import "./NewChat.css";
import ChatTemplate from "./Template";
import { ChatList } from "./ChatList";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useScrollToTop } from "../../../hooks";
import Messages from "./Messages";
import Textbox from "./TextBox";
import AvatarImg from "../../../view/assets/img/avatar.png";

interface UserChatRoomProps {
  checkUserIsOnline: boolean;
}

export const NewChatApp = (props: UserChatRoomProps) => {
  const location = useLocation();

  const [chatRoomID, setChatRoomID] = useState<string>();
  const [selectedConsultant, setSelectedConsultant] = useState<string>();

  document.title = "TRADINN - EXCLUSIVE SERVICES";

  const { inquiry, consultant } = (location.state as any) || {};

  useScrollToTop();

  return (
    <>
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card chat-app">
              <ChatList
                setRoomIdFromChatList={setChatRoomID}
                setConsultantFromChatList={setSelectedConsultant}
              />
              <div className="chat" style={{ height: "580px" }}>
                <div className="chat-header clearfix">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src={AvatarImg} alt="avatar" />
                      <div className="chat-about">
                        <h6 className="m-b-0">
                          {selectedConsultant || "Chat App"}
                        </h6>
                        <div className="status">
                          <i className="fa fa-circle online"></i> online{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 hidden-sm text-right">
                      {/* <a href="javascript:void(0);" className="btn btn-outline-secondary"><i
                                            className="fa fa-camera"></i></a>
                                        <a href="javascript:void(0);" className="btn btn-outline-primary"><i
                                            className="fa fa-image"></i></a>
                                        <a href="javascript:void(0);" className="btn btn-outline-info"><i
                                            className="fa fa-cogs"></i></a> */}
                      <a
                        href="javascript:void(0);"
                        className="btn btn-outline-warning"
                      >
                        <i className="fa fa-question"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <Messages chatRoomID={chatRoomID!} />

                <Textbox chatRoomID={chatRoomID!} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
