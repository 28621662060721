import React, { useState } from "react";
import { Form, Input, Layout, notification, Radio, Select, Spin } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CreateAccountData, LoginData } from "../../../types";
import * as AuthService from "../../../store/auth/auth.actions";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import {
  clearAuthError,
  clearAuthStatus,
} from "../../../store/auth/auth.slice";
import * as routes from "../../../constants/routes";
import activateImg from "../../assets/img/account-verification.png";
import SiteHeader from "../../Supplier/SiteHeader";

export const AccountActivatePage = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { status, error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const token = searchParams.get("token")?.replaceAll(" ", "+");
    const email = searchParams.get("email");

    if (token && email) {
      dispatch(AuthService.activateAccount({ token, email }));
    }
  }, [dispatch]);

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (status === "activateAccountRejected") {
      displayErrorMessage(error);

      setErrorMessage(true);

      dispatch(clearAuthStatus());
    }

    if (status === "activateAccountResolved") {
      setSuccessMessage(true);

      dispatch(clearAuthStatus());
    }
  }, [dispatch, status]);

  useScrollToTop();

  return (
    <>
      <SiteHeader />

      <div id="login-wrapper" className="container-fluid section-container">
        <div className="container">
          <div className="section-heading mb-5 text-center">
            <h1 className="title">
              TradInn empowers high-impact
              <br /> knowledge partners to create risk
              <br /> free investment decisions.
            </h1>

            {successMessage ? (
              <>
                <br />
                <br />
                <br />
                <img src={activateImg} />
                <br />
                <br />
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6">
                    <button
                      type="button"
                      className="button-block"
                      onClick={() => navigate(routes.LOGIN, { replace: true })}
                      style={{
                        width: "100%",
                        display: "block",
                        padding: "10px",
                        cursor: "pointer",
                        color: "#FFF",
                        background: "#000",
                        border: "none",
                        borderRadius: "4px",
                        transition: "all 300ms ease-in-out",
                      }}
                    >
                      Sign In
                    </button>
                    {/* <LoginPage /> */}
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </>
            ) : (
              <>
                {errorMessage ? (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <h3>Error has been occured, Contact help desk.</h3>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {errorMessage ? console.log(error) : ""}
              </>
            )}
          </div>
        </div>
      </div>

      <footer>
        <div className="container">
          <div className="row mb-5 text-center text-lg-left">
            <div className="col-lg-4">
              <img src="/images/tradin.png" />
            </div>
            <div className="col-lg-2">
              <Link to="/">Home</Link>
              <Link to="/consultant/list">Consultant</Link>
              <Link to="/infohub">Info Hub</Link>
              <Link to="/aboutus">About Us</Link>
            </div>
            <div className="col-lg-2">
              <p>
                408 West Green Road, London. N15 3PX
                <br />
                +44 7399 314957
                <br />
                support@tradinn.org.uk
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4">
              <div className="social-icons">
                <img src="/images/in.png" />
                <img src="/images/tw.png" />
                <img src="/images/fb.png" />
              </div>
            </div>
            <div className="col-lg-6">
              <p className="text-right">
                Copyrights © 2024 | All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
