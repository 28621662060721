import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import { UserType } from "../../../enums";
import { Link, useLocation } from "react-router-dom";

const SiteHeader = () => {
  const { user } = useAppSelector((state) => state.auth);

  const location = useLocation();

  console.log(location.pathname);

  return (
    <>
      <header id="header" className="default">
        <div className="menu">
          <nav id="menu" className="mega-menu">
            <section className="menu-list-items">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="menu-logo">
                      <li>
                        <Link to="/home">
                          <img
                            id="logo_img"
                            src="/images/tradin.png"
                            alt="logo"
                          />
                        </Link>
                        <div className="menu-mobile-collapse-trigger">
                          <span></span>
                        </div>
                      </li>
                    </ul>
                    <ul className="menu-links">
                      {user.userName &&
                        user.userType === UserType.CONSULTANT && (
                          <li className="active">
                            <Link to="/consultant/home">Dashboard</Link>
                          </li>
                        )}
                      {user.userName && user.userType === UserType.CUSTOMER && (
                        <li className="active">
                          <Link to="/customer/home">Dashboard</Link>
                        </li>
                      )}
                      {!!!user.userName && (
                        <li className="active">
                          <Link to="/">Home</Link>
                        </li>
                      )}
                      <li>
                        <Link to="/aboutus">About Us</Link>
                      </li>
                      <li>
                        <Link to="/infohub">Info Hub</Link>
                      </li>
                      {user.userName && user.userType === UserType.CUSTOMER && (
                        <li>
                          <Link to="/consultant/list">Consultant</Link>
                        </li>
                      )}
                      {!!!user.userName && (
                        <>
                          <li>
                            <Link to="/login">Login</Link>
                          </li>
                          <li className="signup-btn">
                            <Link to="/signup">Signup</Link>
                          </li>
                        </>
                      )}
                      {user.userName && (
                        <li className="signup-btn">
                          <Link to="/logout">Logout</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </nav>
        </div>
      </header>
    </>
  );
};

export default SiteHeader;
