import React from "react";
// import * as routes from "../constants/routes";
// import { Role } from "../enums";
import { Route, Routes } from "react-router-dom";
// import { CreateAccount, Home, Labs, Login, NotFound } from "../sections";
// import { ProfilePage } from "../view/Profile";
// import { PrivateRoute } from "./private-route";
// import { PublicRoute } from "./public-route";
// import { HomePage } from "../view";
import {
  ProductFormContentNavigation,
  FormNavigation,
  InquiryFormContentNavigation,
  ChatApp,
  MaintenancePage,
  LoginPage,
  CardPaymentPage,
} from "../view";
import { SignupNavigation, NotFound, HomePage } from "../view";
import {
  ContentNavigation,
  ProtectedAdminContentNavigation,
  ProtectedContentNavigation,
  SupplierContentNavigation,
} from "../view/Layout/ContentLayout";
import { ProtectedAdminRoute, ProtectedRoute } from "./PrivateRoute";
import { User } from "../types";
// import { SubscriptionPage } from "../view/Account/Subscription";
import { ConsultantHomePage } from "../view/Consultant/Dashboard/indext";
import { ConsulatantInquiryPage } from "../view/Consultant/Inquiry/New";
import { ConsultantManageProfilePage } from "../view/Consultant/Profile/ManageProfile";
import { ConsultantProfilePage } from "../view/Consultant/Profile/ViewProfile";
import { CustomerHomePage } from "../view/Customer/Dashboard";
import { CustomerInquiryPage } from "../view/Customer/Inquiry";
import { CustomerInquiryListPage } from "../view/Customer/Inquiry/InquiryList";
import { SignupPage } from "../view/Signup/Signup";
import ConsultantSearchListPage from "../view/Consultant/List";
import SharedConsultanProfilePage from "../view/Consultant/Shared/Profile";
import ConsultantInquiryViewPage from "../view/Consultant/Inquiry/View";
import { AccountActivatePage } from "../view/Signup/Activate";
import { CustomerManageProfilePage } from "../view/Customer/Profile/ManageProfile";
import { CustomerProfilePage } from "../view/Customer/Profile/ViewProfile";
import { SubscriptionPage } from "../view/Signup/Subscription";
import { InfoHub } from "../view/Content/InfoHub/InfoHub";
import { IndustrialReports6 } from "../view/Content/InfoHub/IndustrailReports6";
import { IndustrialReports2 } from "../view/Content/InfoHub/IndustrailReports2";
import { IndustrialReports3 } from "../view/Content/InfoHub/IndustrailReports3";
import { IndustrialReports4 } from "../view/Content/InfoHub/IndustrailReports4";
import { IndustrialReports5 } from "../view/Content/InfoHub/IndustrailReports5";
import { Aboutus } from "../view/Content/AboutUs";
import { Industrial_Reports } from "../view/Content/InfoHub/IndustrialReports";
import { ManagePasswordPage } from "../view/Account/ManagePassword";
import { ConsultantForgetPasswordPage } from "../view/Account/ForgetPassword1";
import { CustomerForgetPasswordPage } from "../view/Account/ForgetPassword";
import { CustomerInquiryViewPage } from "../view/Customer/Inquiry/InquiryView";
import { Contactus } from "../view/Content/ContactUs";
import { LogoutPage } from "../view/Account/Logout";

/**
 * Top level application router
 *
 * @returns {Component}
 */
export const Router = ({ user }: { user: User }) => {
  // console.log("user"+JSON.stringify(user));

  return (
    <Routes>
      <Route path="/" element={<HomePage />}></Route>
      <Route path="/home" element={<HomePage />}></Route>
      <Route path="/user/*" element={<SignupNavigation />}></Route>
      <Route path="/account/activate" element={<AccountActivatePage />}></Route>
      <Route path="/subscription" element={<SubscriptionPage />}></Route>
      <Route path="/aboutus" element={<Aboutus />}></Route>
      <Route path="/infohub" element={<InfoHub />}></Route>
      <Route
        path="/expert_collaboration"
        element={<Industrial_Reports />}
      ></Route>
      <Route
        path="/competitive_consultancy"
        element={<IndustrialReports6 />}
      ></Route>
      <Route path="/best_consultants" element={<IndustrialReports2 />}></Route>
      <Route
        path="/become_top_consultant"
        element={<IndustrialReports3 />}
      ></Route>
      <Route
        path="/financial_consultancy"
        element={<IndustrialReports4 />}
      ></Route>
      <Route
        path="/thinking_strategically"
        element={<IndustrialReports5 />}
      ></Route>
      <Route
        path="/chat/app"
        element={
          <ProtectedRoute>
            <ChatApp />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/consultant/home"
        element={
          <ProtectedRoute>
            <ConsultantHomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/contactus" element={<Contactus />}></Route>
      <Route
        path="/customer/home"
        element={
          <ProtectedRoute>
            <CustomerHomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/consultant/*">
        <Route
          path="list"
          element={
            <ProtectedRoute>
              <ConsultantSearchListPage />
            </ProtectedRoute>
          }
        />
        <Route path="profile" element={<SharedConsultanProfilePage />} />
        <Route
          path="inquiries"
          element={
            <ProtectedRoute>
              <ConsulatantInquiryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="manageprofile"
          element={
            <ProtectedRoute>
              <ConsultantManageProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="mainprofile"
          element={
            <ProtectedRoute>
              <ConsultantProfilePage />
            </ProtectedRoute>
          }
        />
        <Route path="inquiry">
          <Route path="view" element={<ConsultantInquiryViewPage />} />
        </Route>
        <Route
          path="forgotpwd"
          element={
            <ProtectedRoute>
              <ConsultantForgetPasswordPage />
            </ProtectedRoute>
          }
        />
        <Route path="resetpwd" element={<ManagePasswordPage />} />
      </Route>

      <Route path="/customer/*">
        <Route
          path="createinquiry"
          element={
            <ProtectedRoute>
              <CustomerInquiryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="inquirylist"
          element={
            <ProtectedRoute>
              <CustomerInquiryListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="inquiryview"
          element={
            <ProtectedRoute>
              <CustomerInquiryViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="mainprofile"
          element={
            <ProtectedRoute>
              <CustomerProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="manageprofile"
          element={
            <ProtectedRoute>
              <CustomerManageProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="forgotpwd"
          element={
            <ProtectedRoute>
              <CustomerForgetPasswordPage />
            </ProtectedRoute>
          }
        />
        <Route path="resetpwd" element={<ManagePasswordPage />} />
      </Route>
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route
        path="/setup/payment"
        element={
          <ProtectedRoute>
            <CardPaymentPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/view/*"
        element={
          <ProtectedRoute
          //   // isAllowed={!!user /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/view/company/*"
        element={
          <ProtectedRoute
          //   // isAllowed={!!user /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <SupplierContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/navigate/*"
        element={
          <ProtectedRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ProtectedContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <ProtectedAdminRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ProtectedAdminContentNavigation />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="/product/*"
        element={
          <ProtectedRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ProductFormContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inquiry/*"
        element={
          <ProtectedRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <InquiryFormContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chat/app"
        element={
          <ProtectedRoute>
            <ChatApp />
          </ProtectedRoute>
        }
      ></Route>
      <Route path="*" element={<NotFound />} />
      <Route path="/404" element={<NotFound />} />

      {/* <Route path="/" element={<HomePage />}></Route>
      <Route path="/user/*" element={<FormNavigation />}></Route>
      <Route path="/view/*" element={<ContentNavigation />}></Route> */}
      {/* <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/signup" element={<SignupPage />}></Route> */}
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};
