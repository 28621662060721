import React from "react";
import { Link } from "react-router-dom";
import ConsultantFooter from "../../Consultant/HomeFooter";
import { useAppSelector, useScrollToTop } from "../../../hooks";
import { UserType } from "../../../enums";
import SiteHeader from "../../Supplier/SiteHeader";

export const InfoHub = () => {
  useScrollToTop();

  const { user } = useAppSelector((state) => state.auth);

  return (
    <>
      <SiteHeader />

      <img className="page-hero" src="/images/info-hub.jpg" />

      <div
        id=""
        className="container-fluid section-container bg-white text-center"
      >
        <div className="container">
          <div className="section-heading mb-5">
            <h1 className="title">Pricing</h1>
          </div>

          <div className="row">
            <aside className="col-lg-4 package">
              <div className="package-info">
                <span className="pkg-tenure">Monthly Package</span>
                <h3>PLAN 01</h3>
                <span className="pkg-value">200 GBP</span>
              </div>
              <a className="pkg-btn" href="#">
                SUBSCRIBE
              </a>
            </aside>
            <aside className="col-lg-4 package">
              <div className="package-info">
                <span className="pkg-tenure">06 Months</span>
                <h3>PLAN 02</h3>
                <span className="pkg-value">500 GBP</span>
              </div>
              <a className="pkg-btn" href="#">
                SUBSCRIBE
              </a>
            </aside>
            <aside className="col-lg-4 package">
              <div className="package-info">
                <span className="pkg-tenure">12 Months</span>
                <h3>PLAN 03</h3>
                <span className="pkg-value">1000 GBP</span>
              </div>
              <a className="pkg-btn" href="#">
                SUBSCRIBE
              </a>
            </aside>
            {/* <aside className="col-lg-3 package">
          <div className="package-info">
            <span className="pkg-tenure">12 Months</span>
            <h3>PLAN 04</h3>
            <span className="pkg-value">200 GBP</span>
          </div>
          <a className="pkg-btn" href="#">SUBSCRIBE</a>
        </aside> */}
          </div>
        </div>
      </div>

      <div className="container-fluid section-container bg-lightgray text-center">
        <div className="container">
          <div className="section-heading">
            <span>Company</span>
            <h1 className="title">
              Your one-stop solution
              <br /> to find consultants
            </h1>
          </div>
          <p className="w-75 my-5 mx-auto">
            We strive to lead the way in delivering innovative consulting
            services that empower consultants and customers. Our goal is to
            provide a seamless, efficient, and innovative online platform that
            sets new standards for service delivery.
          </p>

          <div className="row text-left mt-5">
            <aside className="col-lg-4">
              <h2 className="mb-4">SIGN UP FREE</h2>
              <p>
                Signup free at TRADINN, create your profile and begin exploring
                a professional pool of consultants to get tactical and strategic
                edge for your business.
              </p>
            </aside>
            <aside className="col-lg-4">
              <h2 className="mb-4">POST INQUIRY</h2>
              <p>
                Organize your project, post an inquiry with all the details,
                choose consultants and submit your inquiry and move ahead to
                begin your dream project.
              </p>
            </aside>
            <aside className="col-lg-4">
              <h2 className="mb-4">CONNECT WITH CONSULTANTS</h2>
              <p>
                Get responses to your queries, connect with your favourite
                consultants and begin the conversation!
              </p>
            </aside>
          </div>
          <a className="btn-gold btn-center mt-5" href="/signup">
            SIGN UP
          </a>
        </div>
      </div>

      <div className="container-fluid section-container bg-white text-center">
        <div className="container">
          <div className="section-heading">
            <span>CONSULTANTS </span>
            <h1 className="title">
              Discover the ultimate
              <br /> resource for finding expert
              <br /> consultants
            </h1>
          </div>
          <p className="w-75 my-5 mx-auto">
            TRADINN aims to revolutionize the consulting marketplace, and set
            new standards of excellence. We focus on creating an open and
            dynamic ecosystem where consultants can display their expertise and
            customers can access high-quality services that meet their needs.
          </p>

          <div className="row text-left mt-5">
            <aside className="col-lg-4">
              <h2 className="mb-4">JOIN TRADINN</h2>
              <p>
                Signup to a world of opportunities! Showcase your professional
                profile and your success stories, share your portfolio through
                social links and attract work prospects.
              </p>
            </aside>
            <aside className="col-lg-4">
              <h2 className="mb-4">FIND PROSPECTS</h2>
              <p>
                Just sit back and receive work inquiries all based on your
                profile & portfolio. Find Prospects and work inquiries right in
                your TRADINN dashboard.
              </p>
            </aside>
            <aside className="col-lg-4">
              <h2 className="mb-4">FIND WORK</h2>
              <p>
                Discover what makes you stand out as a consultant. Periodically
                update your profile on TRADINN to unlock new clients and new
                opportunities!
              </p>
            </aside>
          </div>
          <a className="btn-gold btn-center mt-5" href="/signup">
            SIGN UP
          </a>
        </div>
      </div>

      <div className="container-fluid section-container text-center">
        <div className="container">
          <div className="section-heading mb-5">
            <h1 className="title">INDUSTRIAL REPORTS</h1>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <figure className="industrial-card">
                <img src="/images/ir-1.jpg" />
                <figcaption>
                  <Link to="/expert_collaboration">
                    <h4>Expert Collaboration</h4>
                  </Link>
                  <p>
                    Collaboration is essential for success and growth in today's
                    fast-paced corporate environment.
                    <br />
                    15 Jan 2024 | By Admin
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="col-lg-4">
              <figure className="industrial-card">
                <img src="/images/ir-2.jpg" />
                <figcaption>
                  <Link to="/best_consultants">
                    <h4>Perfect Consultant at the Right Price</h4>
                  </Link>
                  <p>
                    Finding the perfect consultant for your business is crucial
                    to unlocking your company's potential.
                    <br />
                    14 Jan 2024 | By Admin
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="col-lg-4">
              <figure className="industrial-card">
                <img src="/images/ir-3.jpg" />
                <figcaption>
                  <Link to="/become_top_consultant">
                    <h4>05 paths to become a top Consultant</h4>
                  </Link>
                  <p>
                    Moving through a career successfully as a consultant
                    requires thoughtful planning and strategic thinking. <br />
                    12 Jan 2024 | By Admin
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="col-lg-4">
              <figure className="industrial-card">
                <img src="/images/ir-4.jpg" />
                <figcaption>
                  <Link to="/financial_consultancy">
                    <h4>Financial Consultancy Tips</h4>
                  </Link>
                  <p>
                    Establishing a business in the UK requires strategic
                    guidance when navigating the financial landscape.
                    <br />
                    10 Jan 2024 | By Admin
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="col-lg-4">
              <figure className="industrial-card">
                <img src="/images/ir-5.jpg" />
                <figcaption>
                  <Link to="/thinking_strategically">
                    <h4>Thinking Strategically</h4>
                  </Link>
                  <p>
                    In today's rapidly changing business environment, making
                    strategic decisions is crucial for businesses to achieve
                    long-term success.
                    <br />8 Jan 2024 | By Admin
                  </p>
                </figcaption>
              </figure>
            </div>
            <div className="col-lg-4">
              <figure className="industrial-card">
                <img src="/images/ir-6.jpg" />
                <figcaption>
                  <Link to="/competitive_consultancy">
                    <h4>Competitive Consultancy</h4>
                  </Link>
                  <p>
                    The United Kingdom is an ideal nation that offers a number
                    of opportunities for professionals worldwide to excel in
                    their respective fields.
                    <br />6 Jan 2024 | By Admin
                  </p>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <ConsultantFooter />
    </>
  );
};
