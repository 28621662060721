import React, { useState } from "react";
import { Form, Input, Layout, notification, Radio, Select, Spin } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CreateAccountData, LoginData } from "../../types";
import * as AuthService from "../../store/auth/auth.actions";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../utils/notifications";
import { clearAuthError, clearAuthStatus } from "../../store/auth/auth.slice";
import { UserType } from "../../enums";
import { CheckoutForm, GoogleLocation, PreLoader } from "../Components";
import * as routes from "../../constants/routes";
import { store } from "../../store";
import * as ListService from "../../store/lists/list.action";
import * as SetupService from "../../store/setups/setups.actions";
import { goOnline } from "firebase/database";
import * as FirestoreService from "../../view/Components/ChatStore/firebase/fireStoreService";
import { useFireBase } from "../Components/ChatStore/firebase/config";
import ConsultantFooter from "../Consultant/HomeFooter";
import SiteHeader from "../Supplier/SiteHeader";

const { Item } = Form;
const { Password } = Input;

export const LoginPage = () => {
  const {
    error,
    status: authStatus,
    user,
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auth, fs, db] = useFireBase();

  const [hidePaymentButton, setHidePaymentButton] = useState<boolean>(true);
  // const [showPaymentCard, setShowPaymentCard] = useState<boolean>(false);
  // const [disableLoginButton, setDisableLoginButton] = useState<boolean>(false);

  const onFormSubmit = ({ UserName, Password }: LoginData) => {
    dispatch(AuthService.logIn({ UserName, Password }));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (store.getState().auth.isAuthenticated) {
      if (user) {
        if (user.userType === UserType.CONSULTANT) {
          navigate(routes.CONS_DASHBOARD);
        } else if (user.userType === UserType.CUSTOMER) {
          navigate(routes.COMP_DASHBOARD);
        } else if (user.userType === UserType.ADMIN) {
          navigate(routes.ADMIN_DASHBOARD);
        }
      }
    }
  }, [navigate, user]);

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);

      dispatch(clearAuthError());
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (authStatus === "registrationFeeNotPaid") {
      sessionStorage.setItem("user.auth", JSON.stringify(user));

      displayErrorMessage("Please pay your registration fee before signing in");

      setHidePaymentButton(false);

      dispatch(clearAuthStatus());
    }

    // if (authStatus === "initPaymentIntentResolved") {
    //   // setShowPaymentCard(true);

    //   navigate(routes.TO_PAYMENT_PAGE);
    // }

    if (authStatus === "loggedIn") {
      sessionStorage.setItem("user.auth", JSON.stringify(user));

      dispatch(AuthService.loadCurrentUser());
      dispatch(ListService.fetchCountries());
      dispatch(ListService.fetchCities(Number(0)));
      dispatch(ListService.fetchAreaCoverage());
      // dispatch(SetupService.fetchCategories());

      // if (user.userType === UserType.SUPPLIER) {
      //   navigate(routes.SUPP_DASHBOARD);
      // } else if (user.userType === UserType.CUSTOMER) {
      //   navigate(routes.COMP_DASHBOARD);
      // }

      const manageUserPresence = async () => {
        const userId = user?.firstName + " " + user?.lastName;

        await goOnline(db);

        await FirestoreService.manageUserPresence(auth, fs, db, String(userId));
      };

      manageUserPresence();

      displaySuccessNotification("Logged-In successfully");
    }

    if (authStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [dispatch, authStatus]);

  return (
    <>
      <SiteHeader />

      <div id="login-wrapper" className="container-fluid section-container">
        <div className="container">
          <div className="section-heading mb-5 text-center">
            <h1 className="title">
              TradInn empowers high-impact
              <br /> knowledge partners to create risk
              <br /> free investment decisions.
            </h1>
          </div>
          <div className="new-account">
            <img className="logo" src="/images/fav-white.png" />
            <a href="#">Login in TradInn</a>
            <span></span>
          </div>
          <Form
            onFinish={onFormSubmit}
            onFinishFailed={onFinishFailed}
            className="login-form"
          >
            <label>Enter Email*</label>
            <Item
              name="UserName"
              className="local-form input-reg"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <input
                className="input-text"
                type="text"
                name=""
                placeholder="Enter Email Address"
              />
            </Item>
            <label>Password</label>
            <Item
              name="Password"
              className="local-form input-reg"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 4,
                  message: "Password must be minimum 4 characters long.",
                },
              ]}
              hasFeedback
            >
              <input
                className="input-text mb-4"
                type="password"
                name=""
                placeholder="Enter Password"
              />
            </Item>
            <button
              type="submit"
              disabled={authStatus === "loggedInPending"}
              style={{
                width: "100%",
                display: "block",
                padding: "10px",
                cursor: "pointer",
                color: "#FFF",
                background: "#000",
                border: "none",
                borderRadius: "4px",
                transition: "all 300ms ease-in-out",
              }}
            >
              <span id="button-text">
                {authStatus === "loggedInPending" ? (
                  <Spin size="small" />
                ) : (
                  "Login"
                )}
              </span>
            </button>
            <br />
            <button
              type="button"
              className="butn butn-block"
              onClick={() => navigate(routes.USER_SUBSCRIPTION)}
              // disabled={authStatus === "initPaymentIntentPending"}
              hidden={hidePaymentButton}
              style={{
                width: "100%",
                display: "block",
                padding: "10px",
                cursor: "pointer",
                color: "#FFF",
                background: "#000",
                border: "none",
                borderRadius: "4px",
                transition: "all 300ms ease-in-out",
              }}
            >
              Pay now
            </button>
          </Form>
        </div>
      </div>

      <ConsultantFooter/>
    </>
  );
};
