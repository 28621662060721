import React, { ReactNode } from "react";
import { ReactChild } from "react";
// import { PageFooter, WhitePageHeader } from "../../../view";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  
  CustomProfilePage,
  MenuNavigator,
  AddProductPage,
  AddServicePage,
  SendInquiryPage,
  
} from "../../../view";
import {
  InquiryFeedbackPage,
  InquiryPurchaseOrderPage,
  SendQuotationPage,
} from "../../Inquiry";
import buysingWhitelogo from "../../assets/BuySing_white_Logo.png";
import { InquiryInvoicePage } from "../../Inquiry/Invoice";
import { relative } from "path";
import * as routes from "../../../constants/routes";
import { store } from "../../../store";
import { UserType } from "../../../enums";
// import { ForgetPasswordPage } from "../../Account/ForgetPassword";
import { ResetPasswordPage } from "../../Account/ResetPassword";
import { CardPaymentPage } from "../../Payment";

const FormLayout = (props: {
  children: ReactChild | ReactNode | null | undefined;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <header className="mainHead">
        <MenuNavigator buysing_logo={buysingWhitelogo} fonts_color="white" />
        <div className="coverBgImg vendorsignupCover">
          <div className="centralForm-lg">
            <div className="container-fluid">
              <button
                style={{ float: "right", width: "25px" }}
                onClick={() =>
                  store.getState().auth.user.userType === UserType.SUPPLIER
                    ? navigate(routes.SUPP_DASHBOARD)
                    : store.getState().auth.user.userType === UserType.CUSTOMER
                    ? navigate(routes.COMP_DASHBOARD)
                    : [UserType.ADMIN, UserType.SUPER_ADMIN].includes(
                        Number(store.getState().auth.user.userType)
                      )
                    ? navigate(routes.ADMIN_DASHBOARD)
                    : navigate(routes.HOME)
                }
              >
                {" "}
                X{" "}
              </button>
              <main>{props.children}</main>
            </div>
          </div>
        </div>
        {/* <PageFooter /> */}
      </header>
    </>
  );
};

export const FormNavigation = () => {
  return (
    <>
      <FormLayout>
        <Routes>
          <Route path="/profile" element={<CustomProfilePage />} />
          <Route path="/payment" element={<CardPaymentPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </FormLayout>
    </>
  );
};

export const ProductFormContentNavigation = () => {
  const location = useLocation();

  return (
    <>
      <FormLayout>
        <Routes>
          <Route path="/add" element={<AddProductPage categoryIndex={0} />} />
          <Route path="/edit" element={<AddProductPage categoryIndex={0} />} />
          <Route
            path="/service/add"
            element={<AddServicePage categoryIndex={0} />}
          />
          <Route
            path="/service/edit"
            element={<AddServicePage categoryIndex={0} />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </FormLayout>
    </>
  );
};

export const InquiryFormContentNavigation = () => {
  const location = useLocation();

  return (
    <>
      <FormLayout>
        <Routes>
          <Route path="/general" element={<SendInquiryPage />} />
          <Route path="/supplier" element={<SendInquiryPage />} />
          <Route path="/product" element={<SendInquiryPage />} />
          <Route path="/edit/:id" element={<SendInquiryPage mode="edit" />} />
          <Route path="/quotation" element={<SendQuotationPage />} />
          <Route
            path="/quotation/edit/:id"
            element={<SendQuotationPage mode="edit" />}
          />
          <Route path="/order" element={<InquiryPurchaseOrderPage />} />
          <Route path="/invoice" element={<InquiryInvoicePage />} />
          <Route path="/receipt" element={<InquiryFeedbackPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </FormLayout>
    </>
  );
};
