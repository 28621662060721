import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import { CreateInquiryData, InquiryResponse, Paging } from "../../types";
import http from "../../utils/http";

export const fetchInquiry = createAsyncThunk(
  "inquiry/fetchInquiry",
  async (id: string, thunkAPI) => {
    try {
      const url = `${config.endpoints.inquiry.fetchInquiry}/${id}`;
      const { data } = await http.get<InquiryResponse>(url);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchUserInquiries = createAsyncThunk(
  "inquiry/fetchUserInquiries",
  async (values: any, thunkAPI) => {
    try {
      let url = `${config.endpoints.inquiry.fetchUserInquiry}`;
      if (Number(values.status) > 0) {
        url += `?status=${values.status}`;
        if (values.pageNo) {
          url += `&pageNumber=${values.pageNo}`;
          url += `&pageSize=${values.pageSize}`;
        }
      } else {
        if (values.pageNo) {
          url += `?pageNumber=${values.pageNo}`;
          url += `&pageSize=${values.pageSize}`;
        }
      }
      const {
        data: { result, paging },
      } = await http.get<{ result: InquiryResponse[]; paging: Paging }>(url);
      return { result, paging };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSupplierInquiries = createAsyncThunk(
  "inquiry/fetchSupplierInquiries",
  async (values: any, thunkAPI) => {
    try {
      let url = `${config.endpoints.inquiry.fetchSupplierInquiry}`;
      if (Number(values.status) > 0) {
        url = `${config.endpoints.inquiry.fetchSupplierInquiry}?status=${values.status}`;
        if (values.pageNo) {
          url += `&pageNumber=${values.pageNo}`;
          url += `&pageSize=${values.pageSize}`;
        }
      } else {
        if (values.pageNo) {
          url += `?pageNumber=${values.pageNo}`;
          url += `&pageSize=${values.pageSize}`;
        }
      }
      const {
        data: { result, paging },
      } = await http.get<{ result: InquiryResponse[]; paging: Paging }>(url);
      return { result, paging };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchConsultantInquiries = createAsyncThunk(
  "inquiry/fetchSupplierInquiries",
  async (values: any, thunkAPI) => {
    try {
      let url = `${config.endpoints.inquiry.fetchConsultantInquiry}`;
      if (Number(values.status) > 0) {
        url = `${config.endpoints.inquiry.fetchConsultantInquiry}?status=${values.status}`;
        if (values.pageNo) {
          url += `&pageNumber=${values.pageNo}`;
          url += `&pageSize=${values.pageSize}`;
        }
      } else {
        if (values.pageNo) {
          url += `?pageNumber=${values.pageNo}`;
          url += `&pageSize=${values.pageSize}`;
        }
      }
      const {
        data: { result, paging },
      } = await http.get<{ result: InquiryResponse[]; paging: Paging }>(url);
      return { result, paging };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createInquiry = createAsyncThunk(
  "inquiry/createInquiry",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.inquiry.createInquiry;

      let inquiryData: CreateInquiryData = {};

      inquiryData = JSON.parse(JSON.stringify(values));

      // if (values?.ImageFiles) {
      //   let localInquiryImages = values?.ImageFiles["fileList"];

      //   inquiryData.inquiryImages = [];
      //   for (let index = 0; index < localInquiryImages.length; index++) {
      //     inquiryData.inquiryImages?.push(
      //       localInquiryImages[index].response.result
      //     );
      //   }
      // }

      const {
        data: { inquiry },
      } = await http.post<{ inquiry: CreateInquiryData }>(url, inquiryData);
      return inquiry;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateInquiry = createAsyncThunk(
  "inquiry/updateInquiry",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.inquiry.updateInquiry}/${values.Id}`;

      let inquiryData: CreateInquiryData = {};

      inquiryData = JSON.parse(JSON.stringify(values));

      inquiryData.inquiryCategories = [];
      for (
        let index = 0;
        index < values?.ServiceInquiryCategories?.length;
        index++
      ) {
        inquiryData.inquiryCategories.push(
          values?.ServiceInquiryCategories[index][
            values?.ServiceInquiryCategories[index].length - 1
          ]
        );
      }
      for (
        let index = 0;
        index < values?.ProductInquiryCategories?.length;
        index++
      ) {
        inquiryData.inquiryCategories.push(
          values?.ProductInquiryCategories[index][
            values?.ProductInquiryCategories[index].length - 1
          ]
        );
      }

      if (values?.ImageFiles) {
        let localInquiryImages = values?.ImageFiles["fileList"];

        inquiryData.inquiryImages = [];
        for (let index = 0; index < localInquiryImages.length; index++) {
          inquiryData.inquiryImages?.push(
            localInquiryImages[index].response.result
          );
        }
      }

      const {
        data: { inquiry },
      } = await http.put<{ inquiry: CreateInquiryData }>(url, inquiryData);
      return inquiry;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateInquiryStatus = createAsyncThunk(
  "inquiry/updateInquiryStatus",
  async (
    { inquiryid, status }: { inquiryid: number; status: number },
    thunkAPI
  ) => {
    try {
      const urls = [
        "",
        "",
        `${config.endpoints.inquiry.acceptInquiry}/${inquiryid}`,
        `${config.endpoints.inquiry.completeInquiry}/${inquiryid}`,
        `${config.endpoints.inquiry.cancelInquiry}/${inquiryid}`,
      ];

      const {
        data: { inquiry },
      } = await http.put<{ inquiry: CreateInquiryData }>(urls[status]);
      return inquiry;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
