import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51OZcv9BQOa0UiIK3pYNDy6hmtNQiBNshIyTVCZfHPway6JzlpCbqYeL4sPgwzpHyOgvkKLfeYE9rKnNRo6qZIlod00Yvo5Md9H"
    );
  }
  return stripePromise;
};

export default getStripe;
