import { useLocation, useNavigate } from "react-router-dom";
import ConsultantFooter from "../../HomeFooter";
import moment from "moment";
import { useScrollToTop } from "../../../../hooks";

const SharedConsultanProfilePage = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { consultant } = (location.state as any) || {};

  useScrollToTop();

  return (
    <>
      <div className="profile-banner">
        <div className="container">
          <div className="pro-cap">
            <h3>{consultant.firstName + " " + consultant.lastName}</h3>
            <p>{consultant.companyInformation}</p>
          </div>
        </div>
        <img src="/images/profile-banne.jpg" />
      </div>

      <div id="profile" className="container-fluid section-container">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 order-2 order-lg-1">
              <h4 className="mt-5 mt-lg-0">Area of focus</h4>

              <ul className="areafocus">
                {consultant?.userAreaOfFocus.map((focus: any) => (
                  <li>{focus.areaOfFocus?.title}</li>
                ))}
                {/* <li>Advance Manufacturing</li>
                <li>Corporate Culture</li>
                <li>Transformation Realized</li> */}
              </ul>
            </aside>
            <aside className="col-lg-9 order-lg-2">
              <div className="profile-name">
                <h3>PROFILE</h3>
                <button
                  onClick={() =>
                    navigate("/customer/createinquiry", {
                      state: { consultant: consultant },
                    })
                  }
                >
                  Create New Inquiry
                </button>
              </div>

              <h4>Bio</h4>

              <p>{consultant.biodata}</p>

              <h4 className="mt-5">About</h4>

              <p>{consultant.aboutUs}</p>

              <h4 className="mt-5">Years of Experience</h4>

              <ul className="areafocus">
                {consultant?.experience?.map((ue: any) => (
                  <li>
                    {moment(ue.experienceFrom).format("MM/DD/yyyy")} to{" "}
                    {moment(ue.experienceTo).format("MM/DD/yyyy")} at{" "}
                    {ue.companyName} as {ue.position}
                  </li>
                ))}{" "}
              </ul>

              <h4 className="mt-5">Personal Information</h4>
              <p>
              {consultant.firstName + " " + consultant.lastName}
                <br />
                {consultant.email}
                <br />
                {consultant.phoneNumber}
              </p>

              <h4 className="mt-5">Company Address</h4>
              <p>
                {consultant.companyName}
                <br />
                {consultant.companyInformation}
                <br />
                {consultant.address}
                <br />
                {consultant.country?.title}
              </p>
              <h4 className="mt-5">Social Links</h4>
              <p>
                {consultant.facebook}
                <br />
                {consultant.linkedin}
                <br />
                {consultant.twitter}
              </p>
            </aside>
          </div>
        </div>
      </div>

      <ConsultantFooter />
    </>
  );
};

export default SharedConsultanProfilePage;
