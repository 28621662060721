import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import CustomerNav from "../MainNav";
import CustomerMainNav from "../ProfileNav";
import axios from "axios";
import http from "../../../utils/http";
import config from "../../../config";
import { PreLoader, SingleUpload } from "../../Components";
import { Checkbox, Form, Typography, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, message, Upload } from "antd";
import CustomerFooter from "../MainFooter";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import * as InquiryService from "../../../store/inquiry/inquiry.actions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  clearInquiryError,
  clearInquiryStatus,
} from "../../../store/inquiry/inquiry.slice";
import CustomerTemplate from "../Template";
import { getAuthToken } from "../../../utils/helper";

const { Item } = Form;

declare global {
  interface Window {
    nextPrev: (x: number, t: string) => void;
    showTab: (x: number, t: string) => void;
  }
}

type InquiryProps = {
  title?: string;
  specification?: string;
  description?: string;
  industryId?: string;
  industryTitle?: string;
  startDate?: string;
  endDate?: string;
  budget?: number;
  additionalInformation?: string;
  inquiryImages?: string[];
  inquiryConsultants?: number[];
};

export const CustomerInquiryPage = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { status: inquiryStatus, error: inquiryError } = useAppSelector(
    (state) => state.inquiry
  );

  const [pagestate, setPageState] = React.useState<InquiryProps>();
  const [industries, setIndustries] = React.useState<any[]>();
  const [consultants, setConsultants] = React.useState<any[]>();

  React.useEffect(() => {
    window.showTab(0, "inquiry-step");

    const fetchIndustry = async () => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(config.endpoints.industry.getAll);

      setIndustries(result);
    };

    setPageState((prevState) => ({ ...prevState, inquiryConsultants: [] }));

    // fetchConsulants();
    fetchIndustry();
  }, []);

  const fetchConsulants = async (indid: string) => {
    const {
      data: { result },
    } = await http.get<{ result: any }>(
      config.endpoints.consultant.getAll.concat("?IndustryId=") + indid
    );

    setConsultants(result);
  };

  const imageSelectCallback = (file: any) => {
    setPageState((prevState) => ({
      ...prevState,
      inquiryImages: [
        ...[prevState?.inquiryImages || []],
        file.response.result,
      ],
    }));
    if (file) form.setFieldsValue({ Image: file.response.result });
  };

  const props: UploadProps = {
    name: "file",
    action: `${config.baseURI}${config.endpoints.file.uploadFile}?type=inquiry`,
    headers: { Authorization: `Bearer ${getAuthToken()}` },
    onChange(info) {
      // if (info.file) form.setFieldsValue({ Image: info.file.response.result });

      if (info.file.status === "done") {
        setPageState((prevState) => ({
          ...prevState,
          inquiryImages: [info.file?.response?.result],
        }));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onFormSubmit = (values: any) => {
    // dispatch(AuthService.forgetPassword(formData));
    dispatch(InquiryService.createInquiry(pagestate));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  React.useEffect(() => {
    if (inquiryError) {
      displayErrorMessage(inquiryError);
      dispatch(clearInquiryError());
    }
  }, [inquiryError]);

  React.useEffect(() => {
    if (inquiryStatus === "createInquiryResolved") {
      displaySuccessNotification("Inquiry has been submitted");

      form.resetFields();

      dispatch(clearInquiryStatus());
    }

    if (inquiryStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [inquiryStatus]);

  const handleCheckboxChange = (event: any, pcons: any) => {
    const employeeId = parseInt(event.target.value, 10);
    const isChecked = event.target.checked;

    if (isChecked) {
      setPageState((prevState: any) => ({
        ...prevState,
        inquiryConsultants: [...prevState.inquiryConsultants, Number(pcons.id)],
      }));
    } else {
      const remcons = pagestate?.inquiryConsultants?.filter(
        (incon: any) => String(incon) !== String(pcons.id)
      );
      setPageState((prevState: any) => ({
        ...prevState,
        inquiryConsultants: remcons,
      }));
    }
  };

  return (
    <>
      <CustomerTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Customer New Inquiry </h1>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <Form
                      id="signUpForm"
                      className="user-form"
                      form={form}
                      onFinish={onFormSubmit}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <span className="step"></span>
                        <span className="step"></span>
                        <span className="step"></span>
                        <span className="step"></span>
                      </div>
                      <div className="inquiry-step">
                        <h4>Project Content</h4>
                        <div className="row">
                          <aside className="col-lg-12">
                            <Typography.Text>
                              Name of your Project
                            </Typography.Text>
                            <Item name="title">
                              <input
                                onChange={(e) =>
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    title: e.target.value,
                                  }))
                                }
                                className="input-text"
                                type="text"
                                placeholder="Enter here ..."
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Typography.Text>Industry Focus</Typography.Text>
                            <Item name="industryId">
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    industryId: e.target.value,
                                    industryTitle: industries?.find(
                                      (ind) =>
                                        String(ind.id) ===
                                        String(e.target.value)
                                    )?.title,
                                  }));

                                  fetchConsulants(e.target.value);
                                }}
                              >
                                <option value="" disabled selected hidden>
                                  Please Choose Industry
                                </option>
                                {industries?.map((ind) => (
                                  <option
                                    key={ind.id.toString()}
                                    value={ind.id}
                                  >
                                    {ind.title}
                                  </option>
                                ))}
                              </select>
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Typography.Text>
                              Specification of Functional Focus
                            </Typography.Text>
                            <Item name="specification">
                              <input
                                onChange={(e) =>
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    specification: e.target.value,
                                  }))
                                }
                                className="input-text"
                                type="text"
                                placeholder="Enter here ..."
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-12">
                            <Typography.Text>
                              Project description / definition of core question
                            </Typography.Text>
                            <Item name="description">
                              <textarea
                                onChange={(e) =>
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    description: e.target.value,
                                  }))
                                }
                                className="textarea"
                                placeholder="Enter here ..."
                              ></textarea>
                            </Item>
                          </aside>
                          <aside className="col-lg-12">
                            <Typography.Text>
                              {/* <SingleUpload
                                    buttonText={"Upload document"}
                                    onImageSelect={imageSelectCallback}
                                    type="category"
                                  /> */}
                              <Upload {...props}>
                                <Button icon={<UploadOutlined />}>
                                  Upload document
                                </Button>
                              </Upload>
                            </Typography.Text>
                          </aside>
                        </div>

                        <h4 className="mt-5">Project Framework</h4>
                        <div className="row">
                          <aside className="col-lg-6">
                            <Typography.Text>
                              Project Start Date
                            </Typography.Text>
                            <Item name="startDate">
                              <input
                                onChange={(e) =>
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    startDate: e.target.value,
                                  }))
                                }
                                className="input-text"
                                type="date"
                                placeholder="Enter here ..."
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Typography.Text>Project End Date</Typography.Text>
                            <Item name="endDate">
                              <input
                                onChange={(e) =>
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    endDate: e.target.value,
                                  }))
                                }
                                className="input-text"
                                type="date"
                                placeholder="Enter here ..."
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Typography.Text>
                              Additional project period information if needed
                            </Typography.Text>
                            <Item name="additionalInformation">
                              <input
                                onChange={(e) =>
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    additionalInformation: e.target.value,
                                  }))
                                }
                                className="input-text"
                                type="text"
                                placeholder="Enter here ..."
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Typography.Text>Project Budget</Typography.Text>
                            <Item name="budget">
                              <input
                                onChange={(e) =>
                                  setPageState((prevState) => ({
                                    ...prevState,
                                    budget: Number(e.target.value),
                                  }))
                                }
                                className="input-text"
                                type="number"
                                placeholder="Enter here ..."
                              />
                            </Item>
                          </aside>
                        </div>
                      </div>
                      <div className="inquiry-step">
                        <h4>Overview</h4>

                        <div className="table-responsive">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td>
                                  <b>Project Title</b>
                                </td>
                                <td>{pagestate?.title}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Industry</b>
                                </td>
                                <td>{pagestate?.industryTitle}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Specification of functional focus</b>
                                </td>
                                <td>{pagestate?.specification}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Project description</b>
                                </td>
                                <td>{pagestate?.description}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Project Period</b>
                                </td>
                                <td>
                                  {pagestate?.startDate} - {pagestate?.endDate}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Attached File</b>
                                </td>
                                <td>
                                  {pagestate?.inquiryImages &&
                                    pagestate?.inquiryImages[0]}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="inquiry-step">
                        <h4 className="mt-5">Consultant List</h4>
                        <div className="table-responsive">
                          <table className="table ">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">S.No </th>
                                <th scope="col">Consultant Name</th>
                                <th scope="col">Consultant Expertise</th>
                                <th scope="col">Years of experience</th>
                                {/* <th scope="col">Connect by</th> */}
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {consultants?.map((cons, index) => (
                                <tr key={(index + 1).toString()}>
                                  <th scope="row">
                                    <Checkbox
                                      onChange={(event) => {
                                        // console.log(pagestate);
                                        // setPageState((prevState: any) => ({
                                        //   ...prevState,
                                        //   inquiryConsultants: [
                                        //     ...prevState.inquiryConsultants,
                                        //     Number(cons.id),
                                        //   ],
                                        // }));

                                        handleCheckboxChange(event, cons);
                                      }}
                                    />
                                  </th>
                                  <th scope="row">{index.toString()}</th>
                                  <td>{cons.firstName || cons.companyName}</td>
                                  <td>
                                    {cons.userAreaOfFocus
                                      ?.map(
                                        (focus: any) => focus.areaOfFocus.title
                                      )
                                      .join(",") || cons.companyInformation}
                                  </td>
                                  <td>7</td>
                                  {/* <td>
                                    <Link to="/chat/app">CHAT</Link>{" "}
                                    <a href="#">EMAIL</a>{" "}
                                    <a href="#">MEETINGS</a>{" "}
                                  </td> */}
                                  <td>
                                    <a href="#">View</a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="inquiry-step">
                        <h4>{pagestate?.title}</h4>
                        <div className="table-responsive">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td>
                                  <b>Industry</b>
                                </td>
                                <td>{pagestate?.title}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Specification of functional focus</b>
                                </td>
                                <td>{pagestate?.specification}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Project description</b>
                                </td>
                                <td>{pagestate?.description}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Project period</b>
                                </td>
                                <td>
                                  {pagestate?.startDate} - {pagestate?.endDate}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Attached File</b>
                                </td>
                                <td>
                                  {pagestate?.inquiryImages &&
                                    pagestate?.inquiryImages[0]}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <h4>Consultant requirements</h4>
                        <div className="table-responsive">
                          <table className="table table-borderless">
                            <tbody>
                              {consultants
                                ?.filter((cons) =>
                                  pagestate?.inquiryConsultants?.includes(
                                    Number(cons.id)
                                  )
                                )
                                .map((cons, index) => (
                                  <Fragment key={index.toString()}>
                                    <tr>
                                      <td>
                                        <b>Name</b>
                                      </td>
                                      <td>{cons.companyName}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Required working experience</b>
                                      </td>
                                      <td>{cons.companyInformation}</td>
                                    </tr>
                                  </Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="form-buttons form-footer">
                        <button
                          type="button"
                          id="prevBtn"
                          onClick={() => window.nextPrev(-1, "inquiry-step")}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          id="nextBtn"
                          onClick={() => window.nextPrev(1, "inquiry-step")}
                        >
                          Next
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomerTemplate>
    </>
  );
};
