import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../../config";
import http from "../../../../utils/http";
import useEffectOnce from "../../../../hooks/useEffectOnce";

export const AdminCustomerList = (props: { force: any }) => {
  const [customer, setCustomer] = React.useState<any[]>();
  useEffect(() => {
    const fetchCustomer = async (indid: string, srctext: string) => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(config.endpoints.customer.getAll);

      setCustomer(result);
    };

    fetchCustomer("", "");
  }, [props.force]);

  return (
    <>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Customer List</h1>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col">S.No </th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Company Info</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customer?.map((cust, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{cust.firstName + " " + cust.lastName}</td>
                          <td>{cust.companyName}</td>
                          <td>{cust.companyInformation}</td>
                          <td>
                            {cust.isActive === true ? "Active" : "In Active"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
