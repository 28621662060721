import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../../config";
import http from "../../../../utils/http";

export const AdminConsultantList = (props: { force: any }) => {
  const [consultants, setConsultants] = React.useState<any[]>();

  useEffect(() => {
    const fetchConsulants = async (indid: string, srctext: string) => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(config.endpoints.consultant.getAll);

      setConsultants(result);
    };

    fetchConsulants("", "");
  }, [props.force]);

  return (
    <>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Consultant List</h1>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col">S.No </th>
                        <th scope="col">Consultant Name</th>
                        <th scope="col">Industry</th>
                        <th scope="col">Area of focus</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultants?.map((cons, index) => (
                        <tr>
                          <td scope="row">{index + 1}</td>
                          <td>{cons.firstName + " " + cons.lastName}</td>
                          <td>{cons.industry?.title}</td>
                          <td>
                            <ul className="areafocus">
                              {cons?.userAreaOfFocus?.map((focus: any) => (
                                <li>{focus.areaOfFocus?.title}</li>
                              ))}
                            </ul>
                          </td>
                          <td>
                            {cons.isActive === true ? "Active" : "In Active"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
