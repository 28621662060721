import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import CustomerTemplate from "../Customer/Template";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Form, Spin } from "antd";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../utils/notifications";
import { clearAuthStatus } from "../../store/auth/auth.slice";
import * as AuthService from "../../store/auth/auth.actions";
import { store } from "../../store";
import useEffectOnce from "../../hooks/useEffectOnce";
import SiteHeader from "../Supplier/SiteHeader";

const { Item } = Form;

export const ManagePasswordPage = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { status: authStatus, error: authError } = useAppSelector(
    (state) => state.auth
  );

  useEffectOnce(() => {
    var user = store.getState().auth.user;

    const token = searchParams.get("token")?.replaceAll(" ", "+");
    const email = searchParams.get("email");

    form.setFieldsValue({ Email: email });
    form.setFieldsValue({ Token: token });
  });

  const onFormSubmit = (formData: any) => {
    dispatch(AuthService.resetPassword(formData));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (authStatus === "resetPasswordRejected") {
      displayErrorMessage(authError);
      dispatch(clearAuthStatus());
    }
    if (authStatus === "resetPasswordResolved") {
      displaySuccessNotification("Reset password successfully.");
      dispatch(clearAuthStatus());
    }
  }, [dispatch, authStatus]);

  return (
    <>
      <SiteHeader />

      <div className="wrapper">
        <div className="main">
          <main className="content">
            <div className="container-fluid p-0">
              <h1 className="h3 mb-3">Password Reset </h1>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="container">
                        <Form
                          id="regForm"
                          className="user-form"
                          layout="vertical"
                          form={form}
                          onFinish={onFormSubmit}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <h4 className="mt-4">Email Address</h4>
                          <div className="row">
                            <Item name="Token" hidden={true}>
                              <input></input>
                            </Item>
                            <aside className="col-lg-12">
                              <Item
                                label="Enter new e-mail address here"
                                name="Email"
                                rules={[
                                  {
                                    type: "email",
                                    message: "The input is not valid E-mail!",
                                  },
                                  {
                                    required: true,
                                    message: "Please input your E-mail!",
                                  },
                                ]}
                              >
                                <input
                                  maxLength={50}
                                  type="email"
                                  className="input-text"
                                  placeholder={"Email ID"}
                                  autoComplete="off"
                                  readOnly
                                />
                              </Item>
                            </aside>
                          </div>

                          <div className="row">
                            <h4 className="mt-4">Password</h4>
                            <aside className="col-lg-12">
                              <Item
                                label="Enter New Password"
                                name="Password"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: "Please confirm your password!",
                                  },
                                ]}
                              >
                                <input
                                  maxLength={30}
                                  type="password"
                                  className="input-text"
                                  placeholder="Confirm Password"
                                  autoComplete="off"
                                />
                              </Item>
                            </aside>
                            <aside className="col-lg-12">
                              <Item
                                label="Confirm New Password"
                                name="ConfirmPassword"
                                dependencies={["Password"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: "Please confirm your password!",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_rule, value) {
                                      if (
                                        !value ||
                                        getFieldValue("Password") === value
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        "The two passwords that you entered do not match!"
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <input
                                  maxLength={30}
                                  type="password"
                                  className="input-text"
                                  placeholder="Confirm Password"
                                  autoComplete="off"
                                />
                              </Item>
                            </aside>
                          </div>
                          <button
                            type="submit"
                            className="btn-save"
                            disabled={authStatus === "resetPasswordPending"}
                          >
                            <span id="button-text">
                              {authStatus === "resetPasswordPending" ? (
                                <Spin size="small" />
                              ) : (
                                "Reset password"
                              )}
                            </span>
                          </button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <footer>
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-5">
              <img src="/images/tradin.png" />
            </div>
            <div className="col-lg-2">
              <Link to="/">Home</Link>
              <a href="#">Consultant</a>
              <a href="#">Info Hub</a>
              <a href="#">About Us</a>
            </div>
            <div className="col-lg-1">
              <a href="#">Address</a>
              <a href="#">info@tradInn.com</a>
              <a href="#">Number</a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="social-icons">
                <img src="/images/in.png" />
                <img src="/images/tw.png" />
                <img src="/images/fb.png" />
              </div>
            </div>
            <div className="col-lg-6">
              <p className="text-right">
                Copyrights � 2023 | All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
