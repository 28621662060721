import React, { useEffect, useState } from "react";
import config from "../../../config";
import http from "../../../utils/http";
import ConsultantFooter from "../HomeFooter";
import ConsultantHeader from "../MainHeader";
import { Link } from "react-router-dom";
import ConsultantTemplate from "../Template";
import ConsultantMainNav from "../MainNav";
import ConsultantMainFooter from "../MainFooter";
import ConsultantProfileNav from "../ProfileNav";
import CustomerTemplate from "../../Customer/Template";

const ConsultantSearchListPage = () => {
  const [consultants, setConsultants] = React.useState<any[]>();
  const [industries, setIndustries] = React.useState<any[]>();
  const [focusAreas, setFocusAreas] = React.useState<any[]>();
  const [cities, setCities] = React.useState<any[]>();
  const [selectedIndustry, setSelectedIndustry] = React.useState<any>();
  const [searchedText, setSearchedText] = React.useState<any>();

  useEffect(() => {
    const fetchIndustry = async () => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(config.endpoints.industry.getAll);

      setIndustries(result);
    };
    const fetchAreas = async () => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(config.endpoints.focusArea.getAll);

      setFocusAreas(result);
    };
    const fetchCities = async () => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(
        config.endpoints.city.fetchCities + "/0"
      );

      setCities(result);
    };

    fetchCities();
    fetchAreas();
    fetchIndustry();
    fetchConsulants("", "");
  }, []);

  const fetchConsulants = async (indid: string, srctext: string) => {
    let url = config.endpoints.consultant.getAll;
    let urlparam = "";

    if (srctext.length > 0) {
      urlparam += `?SearchText=${searchedText}`;
    }
    if (indid.length > 0) {
      if (urlparam.length === 0) {
        urlparam += `?IndustryId=${selectedIndustry}`;
      } else {
        urlparam += `&IndustryId=${selectedIndustry}`;
      }
    }
    url += urlparam;
    const {
      data: { result },
    } = await http.get<{ result: any }>(url);

    setConsultants(result);
  };

  useEffect(() => {
    fetchConsulants(selectedIndustry || "", searchedText || "");
  }, [selectedIndustry, searchedText]);

  return (
    <>
      <CustomerTemplate>
        <form className="consultant-searchbar" action="">
          <div className="container">
            <div className="row">
              <aside className="col-lg-4">
                <div className="select-group">
                  <i className="fas fa-search"></i>
                  <input
                    className="input-text"
                    type="text"
                    name=""
                    placeholder="Search Consultant"
                    onChange={(e) => setSearchedText(e.target.value)}
                  />
                </div>
              </aside>
              <aside className="col-lg-8">
                <div className="select-group">
                  <label>Search By</label>
                  <select className="form-select">
                    <option value="">Years of Experience</option>
                    <option>1 Year</option>
                    <option>2 Years</option>
                    <option>3 Years</option>
                    <option>4 Years</option>
                    <option>5 Years</option>
                  </select>
                  <select
                    className="form-select"
                    onChange={(e) => setSelectedIndustry(e.target.value)}
                  >
                    <option value="">Industry</option>
                    {industries?.map((ind) => (
                      <option key={ind.id.toString()} value={ind.id}>
                        {ind.title}
                      </option>
                    ))}
                  </select>
                  <select className="form-select">
                    <option value="">Topic of interest</option>
                    {focusAreas?.map((area) => (
                      <option key={area.id.toString()} value={area.id}>
                        {area.title}
                      </option>
                    ))}
                  </select>
                  <select className="form-select">
                    <option value="">City</option>
                    {cities?.map((city) => (
                      <option key={city.id.toString()} value={city.id}>
                        {city.title}
                      </option>
                    ))}
                  </select>
                </div>
              </aside>
            </div>
          </div>
        </form>

        <div id="" className="container-fluid section-container">
          <div className="container">
            <div className="row">
              {consultants?.map((cons) => (
                <aside className="col-lg-4">
                  <Link to="/consultant/profile" state={{ consultant: cons }}>
                    <figure
                      className="consultant-listing"
                      style={{ border: "1px solid lightgrey" }}
                    >
                      <img src="/images/c1.svg" />
                      <figcaption>
                        <h3 style={{ color: "white" }}>
                          {cons.firstName + " " + cons.lastName}
                        </h3>
                        <span>{cons.companyInformation}</span>
                        <p>{cons.aboutUs}</p>
                      </figcaption>
                    </figure>
                  </Link>
                </aside>
              ))}
            </div>
          </div>
        </div>
      </CustomerTemplate>{" "}
    </>
  );
};

export default ConsultantSearchListPage;
