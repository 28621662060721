import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../../config";
import http from "../../../../utils/http";
import moment from "moment";
import { useAppSelector } from "../../../../hooks";
import { displayErrorMessage, displaySuccessNotification } from "../../../../utils/notifications";

export const AdminInquiryList = (props: { force: number }) => {
  const [inquiries, setInquiries] = React.useState<any[]>();

  useEffect(() => {
    const fetchInquiries = async (indid: string, srctext: string) => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(
        config.endpoints.inquiry.fetchInquiry
      );

      setInquiries(result);
    };

    fetchInquiries("", "");
  }, [props.force]);
  
  const { status: inquiryStatus } = useAppSelector(state=>state.inquiry);

  useEffect(() => {
    if (inquiryStatus === "updateInquiryResolved") {
      displaySuccessNotification("Inquiry has been updated.");
    }

    if (inquiryStatus === "updateInquiryRejected") {
      displayErrorMessage("Unable to update the inquiry.");
    }
  }, [inquiryStatus]);

  return (
    <>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Inquires List</h1>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col">S.No </th>
                        <th scope="col">Inquiry Title</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Budget</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inquiries?.map((inq, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{inq.title}</td>
                          <td>{moment(inq.startDate).format("DD-MM-YYYY")}</td>
                          <td>{moment(inq.endDate).format("DD-MM-YYYY")}</td>
                          <td>{inq.budget}</td>
                          <td>
                            <Link
                              to="/admin/inquiry/view"
                              state={{ inquiry: inq }}
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
