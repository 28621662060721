import { Form, Select } from "antd";
import { useAppSelector } from "../../../hooks";

const { Option } = Select;
const { Item } = Form;

interface AreaCoverageDropDownProps {
  itemName: string;
  itemLabel?: string;
  isRequired: boolean;
  validationMessage?: string;
  setAreaCoverageCallback?: (area: string) => void;
}

export const AreaCoverageDropDown = (props: AreaCoverageDropDownProps) => {
  const {
    status: listStatus,
    error: listError,
    entities: listEntities,
  } = useAppSelector((state) => state.list);

  return (
    <>
      <Item name={props.itemName} label={props.itemLabel}>
        <Select
          placeholder="Area"
          onChange={props.setAreaCoverageCallback}
          mode="multiple"
        >
          <Option key="" value="">
            none
          </Option>
          {/* {listEntities.areaCoverages?.map((area, index) => (
            <Option value={area.id} key={index}>
              {area.city}
            </Option>
          ))} */}
        </Select>
      </Item>
    </>
  );
};
