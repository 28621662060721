import { useEffect, useRef, useState } from "react";
import {
  collection,
  getDocs,
  onSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
// import { userTheme } from "./SetTheme";
import { store } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { useLocation } from "react-router-dom";
import { QuotationResponse } from "../../../types";
import { useFireBase } from "./firebase/config";
import { userTheme } from "./SetTheme";
import * as helper from "../../../utils/helper";
import * as FireStoreActions from "../../../store/firestore/firestore.actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Space } from "antd";

interface MessagesProps {
  chatRoomID: string;
}

export default function Messages(props: MessagesProps) {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { inquiry, consultant } = (location.state as any) || {};

  const [auth, fs, db] = useFireBase();

  const { user } = useAppSelector((state) => state.auth);

  const [messages, setMessages] = useState<any[]>([]);

  const chatRoomId = helper.getEncodedString(
    String(inquiry.userId),
    String(inquiry.id),
    String(consultant.id)
  );

  const userChatRoomID =
    props.chatRoomID === undefined ? chatRoomId : props.chatRoomID;

  const formatAndSetMessages = (querySnapshot: QuerySnapshot) => {
    const allMessages: any[] = [];
    querySnapshot.docs.forEach((doc) => {
      const addedMessage = {
        id: doc.id,
        sender: doc.data().sender,
        content: doc.data().content,
        timestamp: doc.data().timestamp,
        sentByMe: doc.data().senderID === user.userName,
      };
      allMessages.push(addedMessage);
    });
    allMessages.sort(
      (a: { timestamp: string }, b: { timestamp: string }) =>
        parseFloat(a.timestamp) - parseFloat(b.timestamp)
    );
    setMessages([...allMessages]);
  };

  userTheme();
  useEffect(() => {
    onSnapshot(
      collection(fs, "Chats", String(userChatRoomID), "messages"),
      (querySnapshot) => {
        formatAndSetMessages(querySnapshot);
      }
    );
  }, [onSnapshot, props.chatRoomID]);

  /*useEffect(() => {
      const fetchRoomMessages = async () => {
        const q = collection(fs, "Chats", String(props.chatRoomID), "messages");

        const querySnapshot = await getDocs(q);

        // const dataMessages = querySnapshot.docs.map((doc) => doc.data());

        formatAndSetMessages(querySnapshot);
      };

      if (props.chatRoomID && props.chatRoomID.length > 0) {
        fetchRoomMessages();
      }
    }, [props.chatRoomID]);*/

  return (
    <>
      {/* <Scrollbars style={{ width: "100%", height: 400 }} ref={scrollbarsRef}> */}
      <div className="chat-history">
        <ul className="m-b-0">
          {/*<li className="clearfix">*/}
          {/*    <div className="message-data text-right">*/}
          {/*        <span className="message-data-time">10:10 AM, Today</span>*/}
          {/*        <img src="https://bootdey.com/img/Content/avatar/avatar7.png"*/}
          {/*             alt="avatar"/>*/}
          {/*    </div>*/}
          {/*    <div className="message other-message float-right"> Hi Aiden, how are you?*/}
          {/*        How*/}
          {/*        is the project coming along?*/}
          {/*    </div>*/}
          {/*</li>*/}
          {messages ? (
            messages.map((message, index) => (
              <li className="clearfix" key={index.toString()}>
                <div
                  className={
                    message.sentByMe
                      ? "message-data"
                      : "message-data text-right"
                  }
                >
                  <Space direction="horizontal">
                    {<div className="msg-sender">{message.sender}</div>}
                    <span className="message-data-time">
                      {new Date(message.timestamp).toLocaleString()}
                    </span>
                  </Space>
                </div>
                <div
                  className={
                    message.sentByMe
                      ? "message my-message"
                      : "message other-message float-right"
                  }
                >
                  {message.content}
                </div>
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      </div>
      {/*<div className="msg-block">*/}
      {/*    <div className="messages">*/}
      {/*        {messages*/}
      {/*            ? messages.map((message) => (*/}
      {/*                <div*/}
      {/*                    id={message.id}*/}
      {/*                    key={message.id}*/}
      {/*                    className={*/}
      {/*                        message.sentByMe ? "msg sentByYou" : "msg sentByOther"*/}
      {/*                    }*/}
      {/*                >*/}
      {/*                    <div className="msg-left">*/}
      {/*                        <div className="msg-sender">{message.sender}</div>*/}
      {/*                        <div className="msg-content">*/}
      {/*                            <span>{message.content}</span>*/}
      {/*                        </div>*/}
      {/*                    </div>*/}
      {/*                    <div className="msg-right">*/}
      {/*                        {new Date(message.timestamp).toLocaleString()}*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            ))*/}
      {/*            : ""}*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/* </Scrollbars> */}
    </>
  );
}
