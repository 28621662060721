import React from "react";
import { Link } from "react-router-dom";
import ConsultantTemplate from "../Template";
import { useScrollToTop } from "../../../hooks";

export const ConsultantHomePage = () => {
  useScrollToTop();

  return (
    <>
      <ConsultantTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Welcome to TradInn! </h1>

          <div className="row">
            <div className="col-12">
              <div className="row">
                <aside className="col-lg-4">
                  <div className="profile-step">
                    <span>
                      <h3>Step 01</h3>
                      <img src="/images/step1.svg" />
                    </span>
                    <p>To get started, please create your profile.</p>
                  </div>
                </aside>
                <aside className="col-lg-4">
                  <div className="profile-step">
                    <span>
                      <h3>Step 02</h3>
                      <img src="/images/c-s2.svg" />
                    </span>
                    <p>
                      To find incoming inquiries, click on view Inquiry in the
                      navigation panel.
                    </p>
                  </div>
                </aside>
                <aside className="col-lg-4">
                  <div className="profile-step">
                    <span>
                      <h3>Step 03</h3>
                      <img src="/images/c-s3.svg" />
                    </span>
                    <p>Choose an inquiry and connect with the prospect.</p>
                  </div>
                </aside>
                <aside className="col-lg-4">
                  <div className="profile-step">
                    <span>
                      <h3>Step 04</h3>
                      <img src="/images/c-s4.svg" />
                    </span>
                    <p>Grow your network and go further!</p>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </ConsultantTemplate>
    </>
  );
};
