import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ConsultantTemplate from "../Template";
import { useAppSelector } from "../../../hooks";
import moment from "moment";

export const ConsultantProfilePage = () => {
  const navigate = useNavigate();

  const { status, error, user } = useAppSelector((state) => state.auth);

  return (
    <>
      <ConsultantTemplate>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <img src="/images/banner.jpg" className="w-100" />
                  {/* <img src="/images/c-s4.svg" /> */}
                  <div className="profile-name">
                    <h3>
                      {user?.firstName} {user?.lastName}
                    </h3>
                    <button
                      onClick={() => navigate("/consultant/manageprofile")}
                    >
                      Edit
                    </button>
                  </div>

                  <h4>Consultancy Profile</h4>

                  <h5>
                    <b>Bio</b>
                  </h5>
                  <p>{user?.bioData || (user as any)?.biodata}</p>

                  <h5>
                    <b>About</b>
                  </h5>
                  <p>{user?.aboutUs}</p>

                  <div className="row mt-5">
                    <aside className="col-lg-6">
                      <div className="profile-view">
                        <h4>Personal Information</h4>
                        <ul>
                          <li>{user?.companyName}</li>
                          <li>{user?.address}</li>
                          <li>{user?.email}</li>
                          <li>{user?.phoneNumber}</li>
                          <li>{user?.mobileNumber}</li>
                        </ul>
                      </div>
                    </aside>
                    <aside className="col-lg-6">
                      <div className="profile-view">
                        <h4>Company Info</h4>
                        <ul>
                          <li>{user?.companyName}</li>
                          <li>{user?.companyInformation}</li>
                          <li>{user?.country?.title}</li>
                        </ul>
                      </div>
                    </aside>
                  </div>

                  <div className="row mt-4">
                    <aside className="col-lg-6">
                      <div className="profile-view">
                        <h4>Area of Focus</h4>
                        <ul className="areafocus">
                          {user?.areaOfFocus?.map((ua) => (
                            <li>{ua.title}</li>
                          ))}
                        </ul>
                      </div>
                    </aside>
                    <aside className="col-lg-6">
                      <div className="profile-view">
                        <h4>Years of Experience</h4>
                        <ul className="areafocus">
                          {user?.experience?.map((ue) => (
                            <li>
                              {moment(ue.experienceFrom).format("MM/DD/yyyy")}{" "}
                              to {moment(ue.experienceTo).format("MM/DD/yyyy")}{" "}
                              at {ue.companyName} as {ue.position}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </aside>
                    <aside className="col-lg-6">
                      <div className="profile-view">
                        <h4>Social Links</h4>
                        <ul>
                          <li>{user.facebook}</li>
                          <li>{user.linkedin}</li>
                          <li>{user.twitter}</li>
                        </ul>
                      </div>
                    </aside>
                    <aside className="col-lg-6">
                      <div className="profile-view">
                        <h4>Industry</h4>
                        <ul>
                          <li>{user.industry?.title}</li>
                        </ul>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConsultantTemplate>
    </>
  );
};
