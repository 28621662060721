import { createSlice } from "@reduxjs/toolkit";
import { InquiryState } from "../../types";
import * as InquiryService from "./inquiry.actions";
import * as Util from "../../utils/helper";

export const initialState: InquiryState = Object.freeze({
  status: "idle",
  inquiries: [],
  error: "",
  paging: { pageNumber: 1, pageSize: 10, totalCount: 0 },
});

const inquirySlice = createSlice({
  name: "inquiry",
  initialState,
  reducers: {
    clearInquiryError: (state) => {
      state.error = "";
    },
    clearInquiryStatus: (state) => {
      state.status = "idle";
    },
    resetInquiryState: (state) => {
      state = {
        status: "idle",
        inquiries: [],
        error: "",
        paging: { pageNumber: 1, pageSize: 10, totalCount: 0 },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(InquiryService.fetchInquiry.pending, (state) => {
      state.status = "fetchInquiryPending";
    });

    builder.addCase(
      InquiryService.fetchInquiry.fulfilled,
      (state, { payload }) => {
        // state.inquiry = payload;
        state.status = "fetchInquiryResolved";
        state.error = "";
      }
    );

    builder.addCase(
      InquiryService.fetchInquiry.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "fetchInquiryRejected";
      }
    );

    builder.addCase(InquiryService.createInquiry.pending, (state) => {
      state.status = "createInquiryPending";
    });
    builder.addCase(
      InquiryService.createInquiry.fulfilled,
      (state, { payload }) => {
        // state.inquiry = payload;
        state.status = "createInquiryResolved";
        state.error = "";
      }
    );
    builder.addCase(
      InquiryService.createInquiry.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createInquiryRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(InquiryService.updateInquiry.pending, (state) => {
      state.status = "createInquiryPending";
    });
    builder.addCase(
      InquiryService.updateInquiry.fulfilled,
      (state, { payload }) => {
        // state.inquiry = payload;
        state.status = "createInquiryResolved";
        state.error = "";
      }
    );
    builder.addCase(
      InquiryService.updateInquiry.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createInquiryRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(InquiryService.updateInquiryStatus.pending, (state) => {
      state.status = "updateInquiryPending";
    });
    builder.addCase(
      InquiryService.updateInquiryStatus.fulfilled,
      (state, { payload }) => {
        // state.inquiry = payload;
        state.status = "updateInquiryResolved";
        state.error = "";
      }
    );
    builder.addCase(
      InquiryService.updateInquiryStatus.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "updateInquiryRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(InquiryService.fetchUserInquiries.pending, (state) => {
      state.status = "fetchUserInquiriesPending";
    });
    builder.addCase(
      InquiryService.fetchUserInquiries.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchUserInquiriesRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );
    builder.addCase(
      InquiryService.fetchUserInquiries.fulfilled,
      (state, { payload }) => {
        state.status = "fetchUserInquiriesResolved";

        state.inquiries = Util.fill(payload.result);

        state.paging = payload.paging;
      }
    );

    builder.addCase(InquiryService.fetchSupplierInquiries.pending, (state) => {
      state.status = "fetchSupplierInquiriesPending";
    });
    builder.addCase(
      InquiryService.fetchSupplierInquiries.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchSupplierInquiriesRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );
    builder.addCase(
      InquiryService.fetchSupplierInquiries.fulfilled,
      (state, { payload }) => {
        state.status = "fetchSupplierInquiriesResolved";

        state.inquiries = Util.fill(payload.result);

        state.paging = payload.paging;
      }
    );
  },
});

export const { clearInquiryError, resetInquiryState, clearInquiryStatus } =
  inquirySlice.actions;

export default inquirySlice.reducer;
