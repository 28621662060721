import { Link, useLocation } from "react-router-dom";
import CustomerTemplate from "../Template";
import moment from "moment";
import { Space, Tooltip } from "antd";
import * as routes from "../../../constants/routes";
import config from "../../../config";
import { WechatOutlined } from "@ant-design/icons";

export const CustomerInquiryViewPage = () => {
  const location = useLocation();

  const { inquiry } = (location.state as any) || {};

  return (
    <>
      <CustomerTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Published Inquiry View</h1>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4>{inquiry?.title}</h4>
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td>
                            <b>Industry</b>
                          </td>
                          <td>{inquiry?.industry?.title}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Specification of functional focus</b>
                          </td>
                          <td>{inquiry?.specification}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Project description</b>
                          </td>
                          <td>{inquiry?.description}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Project period</b>
                          </td>
                          <td>
                            {moment(inquiry?.startDate).format("MM/DD/yyyy")} -
                            {moment(inquiry?.endDate).format("MM/DD/yyyy")}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Attached File</b>
                          </td>
                          <td>
                            {
                              <Space direction="vertical">
                                {inquiry?.images?.forEach((img: any) => {
                                  <Link
                                    to={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${img.fileName}&type=inquiry`}
                                  >
                                    {img.fileName}
                                  </Link>;
                                })}
                              </Space>
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h4 className="mt-5">Consultant requirements</h4>
                  {inquiry?.inquiryConsultant?.map((con: any) => (
                    <>
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div> {con.applicationUser.companyName}</div>
                                  <div style={{ marginLeft: "auto" }}>
                                    <Link
                                      to={routes.TO_CHAT_APP}
                                      state={{
                                        inquiry: inquiry,
                                        consultant: con.applicationUser,
                                      }}
                                    >
                                      <Tooltip title="Start Chat">
                                        <WechatOutlined
                                          style={{ fontSize: "32px", color:"#D4B35F"}}
                                        />
                                      </Tooltip>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Required working experience</b>
                              </td>
                              <td>
                                <Space direction="vertical">
                                  {con.applicationUser.companyInformation}
                                  {con.applicationUser?.industry?.title}
                                </Space>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* <hr /> */}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomerTemplate>
    </>
  );
};
