import React, { useState } from "react";
import { Link } from "react-router-dom";
import ConsultantTemplate from "../Template";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Row,
  Steps,
  Upload,
  UploadProps,
  message,
  notification,
} from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import http from "../../../utils/http";
import config from "../../../config";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import {
  clearAuthError,
  clearAuthStatus,
} from "../../../store/auth/auth.slice";
import { PreLoader } from "../../Components";
import * as AuthService from "../../../store/auth/auth.actions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import moment from "moment";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { getAuthToken } from "../../../utils/helper";
import * as routes from "../../../constants/routes";

declare global {
  interface Window {
    nextPrev: (x: number, t: string) => void;
    showTab: (x: number, t: string) => void;
  }
}

const { Item } = Form;

export const ConsultantManageProfilePage = () => {
  const [form] = Form.useForm();

  const [industries, setIndustries] = React.useState<any[]>();
  const [areasOfFocus, setAreasOfFocus] = React.useState<number[]>([]);
  // const [current, setCurrent] = useState(0);

  // const next = () => {
  //   setCurrent(current + 1);
  // };

  // const prev = () => {
  //   setCurrent(current - 1);
  // };

  //   const [experienceState, setExperienceState] = React.useState<{
  //     Experience: [
  //       {
  //         Id: number;
  //         Position?: string;
  //         CompanyName?: string;
  //         ExperienceFrom?: Date;
  //         ExperienceTo?: Date;
  //       }
  //     ];
  //   }>();

  // const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const dispatch = useAppDispatch();

  const { status, error, user } = useAppSelector((state) => state.auth);

  const {
    status: listStatus,
    error: listError,
    entities,
  } = useAppSelector((state) => state.list);

  React.useEffect(() => {
    window.showTab(0, "tab");

    const fetchIndustry = async () => {
      const {
        data: { result },
      } = await http.get<{ result: any }>(config.endpoints.industry.getAll);

      setIndustries(result);
    };

    fetchIndustry();

    const getFieldValue = (obj: any, ind: number, name: string) => {
      if (obj) {
        if (obj[ind]) {
          return obj[ind][name];
        }
      }

      return "";
    };
    const getDateFieldValue = (obj: any, ind: number, name: string) => {
      if (obj) {
        if (obj[ind]) {
          return obj[ind][name].slice(0, 10);
        }
      }

      return "";
    };

    form.setFieldsValue({
      FirstName: user?.firstName,
      LastName: user?.lastName,
      MobileNumber: user?.mobileNumber,
      PhoneNumber: user?.phoneNumber,
      Email: user?.email,
      CompanyName: user?.companyName,
      CompanyInformation: user?.companyInformation,
      PostalCode: user?.postalCode,
      CountryId: user?.country?.id,
      CityId: user?.city?.id,
      IndustryId: user?.industry?.id,
      PositionTitle1: getFieldValue(user?.experience || [], 0, "position"),
      PositionTitle2: getFieldValue(user?.experience || [], 1, "position"),
      PositionTitle3: getFieldValue(user?.experience || [], 2, "position"),
      PositionCompanyTitle1: getFieldValue(
        user?.experience || [],
        0,
        "companyName"
      ),
      PositionCompanyTitle2: getFieldValue(
        user?.experience || [],
        1,
        "companyName"
      ),
      PositionCompanyTitle3: getFieldValue(
        user?.experience || [],
        2,
        "companyName"
      ),
      ExperienceFrom1: getDateFieldValue(
        user?.experience || [],
        0,
        "experienceFrom"
      ),
      ExperienceFrom2: getDateFieldValue(
        user?.experience || [],
        1,
        "experienceFrom"
      ),
      ExperienceFrom3: getDateFieldValue(
        user?.experience || [],
        2,
        "experienceFrom"
      ),
      ExperienceTo1: getDateFieldValue(
        user?.experience || [],
        0,
        "experienceTo"
      ),
      ExperienceTo2: getDateFieldValue(
        user?.experience || [],
        1,
        "experienceTo"
      ),
      ExperienceTo3: getDateFieldValue(
        user?.experience || [],
        2,
        "experienceTo"
      ),
      Biodata: user?.bioData || (user as any)?.biodata,
      AboutUs: user?.aboutUs,
      Facebook: user?.facebook,
      Linkedin: user?.linkedin,
      Twitter: user?.twitter,
    });

    setAreasOfFocus(user?.areaOfFocus?.map((area) => Number(area.id)) || []);

    // return () => {
    //   if (error) {
    //     console.log(error);
    //     displayErrorMessage(error);
    //     dispatch(clearAuthError());
    //   }
    // };
  }, []);

  const onChange = (checkedValues: any) => {
    setAreasOfFocus(checkedValues);
  };

  React.useEffect(() => {
    if (status === "userProfileUpdated" && !error) {
      displaySuccessNotification("Profile has been updated");

      dispatch(AuthService.loadCurrentUser());

      dispatch(clearAuthStatus());
    }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [status, error]);

  const onFormSubmit = (values: any) => {
    values.Experience = [];

    values.Experience.push({
      Position: values.PositionTitle1,
      CompanyName: values.PositionCompanyTitle1,
      ExperienceFrom: values.ExperienceFrom1,
      ExperienceTo: values.ExperienceTo1,
    });

    if (values.PositionTitle2.length > 0) {
      values.Experience.push({
        Position: values.PositionTitle2,
        CompanyName: values.PositionCompanyTitle2,
        ExperienceFrom: values.ExperienceFrom2,
        ExperienceTo: values.ExperienceTo2,
      });
    }

    if (values.PositionTitle3.length > 0) {
      values.Experience.push({
        Position: values.PositionTitle3,
        CompanyName: values.PositionCompanyTitle3,
        ExperienceFrom: values.ExperienceFrom3,
        ExperienceTo: values.ExperienceTo3,
      });
    }

    values.AreaOfFocus = areasOfFocus;

    values.Image = form.getFieldValue("Image");

    delete values.PositionTitle1;
    delete values.PositionTitle2;
    delete values.PositionTitle3;

    delete values.PositionCompanyTitle1;
    delete values.PositionCompanyTitle2;
    delete values.PositionCompanyTitle3;

    delete values.ExperienceFrom1;
    delete values.ExperienceFrom2;
    delete values.ExperienceFrom3;

    delete values.ExperienceTo1;
    delete values.ExperienceTo2;
    delete values.ExperienceTo3;

    dispatch(AuthService.createProfile(values));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  const props: UploadProps = {
    name: "file",
    action: `${config.baseURI}${config.endpoints.file.uploadFile}?type=user`,
    headers: { Authorization: `Bearer ${getAuthToken()}` },
    onChange(info) {
      // setPageState((prevState) => ({
      //   ...prevState,
      //   inquiryImages: [info.file.name],
      // }));

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        if (info.file && info.file.response.result) {
          form.setFieldsValue({ Image: info.file.response.result });
        }
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <ConsultantTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Consultant Profile </h1>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    {/* <Steps current={current} items={items}> */}

                    <Form
                      form={form}
                      id="regForm"
                      className="user-form"
                      layout="vertical"
                      onFinish={onFormSubmit}
                      onFinishFailed={onFinishFailed}
                      autoComplete="false"
                    >
                      <section className="tab">
                        <h4>Personal Profile</h4>
                        <div className="row">
                          <aside className="col-lg-6">
                            <Item
                              name="FirstName"
                              label="First Name"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter your First Name",
                              //   },
                              // ]}
                            >
                              <input
                                className="input-text"
                                placeholder="First Name"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="LastName" label="Last Name">
                              <input
                                className="input-text"
                                placeholder="last Name"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item
                              name="MobileNumber"
                              label="Mobile Phone Number"
                            >
                              <input
                                className="input-text"
                                placeholder="Mobile Number"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-6">
                            <Item name="PhoneNumber" label="Phone Number">
                              <input
                                className="input-text"
                                placeholder="Phone Number"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-12">
                            <Item
                              name="Email"
                              label="Email Address"
                              rules={[
                                {
                                  type: "email",
                                  message: "The input is not valid E-mail!",
                                },
                              ]}
                            >
                              <input
                                className="input-text"
                                placeholder="Email Address"
                              />
                            </Item>
                          </aside>
                        </div>

                        <h4 className="mt-4">Company Address</h4>
                        <div className="row">
                          <aside className="col-lg-12">
                            <Item
                              name="CompanyName"
                              label="Company name (including legal form)"
                            >
                              <input
                                className="input-text"
                                placeholder="Company Name"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-12">
                            <Item
                              name="CompanyInformation"
                              label="Additional company information"
                            >
                              <input
                                className="input-text"
                                placeholder="Additional Company Information"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-4">
                            <Item name="PostalCode" label="Postal Code">
                              <input
                                className="input-text"
                                placeholder="Postal Code"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-4">
                            <Item name="CountryId" label="Country">
                              <select className="form-select">
                                <option value="-1">Select Country</option>
                                {entities.countries?.map((country, index) => (
                                  <option value={country.id} key={index}>
                                    {country.title}
                                  </option>
                                ))}
                              </select>
                            </Item>
                          </aside>
                          <aside className="col-lg-4">
                            <Item
                              name="CityId"
                              label="City"
                              // rules={[
                              //   {
                              //     required: false,
                              //     message: "Please input your City",
                              //   },
                              // ]}
                            >
                              <select className="form-select">
                                <option value="-1">Select City</option>
                                {entities.cities?.map((city, index) => (
                                  <option value={city.id} key={index}>
                                    {city.title}
                                  </option>
                                ))}
                              </select>
                            </Item>
                          </aside>
                        </div>
                      </section>

                      <section className="tab">
                        <h4 className="mt-4">Consultant Profile Form </h4>
                        <div className="row">
                          <aside className="col-lg-12">
                            <Item name="IndustryId" label="Select Industry">
                              <select className="form-select">
                                <option value="">Please Choose Industry</option>
                                {industries?.map((ind) => (
                                  <option
                                    key={ind.id.toString()}
                                    value={ind.id}
                                  >
                                    {ind.title}
                                  </option>
                                ))}
                              </select>
                            </Item>
                          </aside>
                        </div>

                        <h4 className="mt-4">Years of experience</h4>
                        <div className="row">
                          <ExperienceFields
                            index={1}
                            // setState={setExperienceState}
                          />
                          <ExperienceFields
                            index={2}
                            // setState={setExperienceState}
                          />
                          <ExperienceFields
                            index={3}
                            // setState={setExperienceState}
                          />
                        </div>

                        <h4 className="mt-4">Choose Area of Focus</h4>
                        <div className="row">
                          <aside className="col-lg-6">
                            <Checkbox.Group
                              name="AreaOfFocus"
                              style={{ width: "100%" }}
                              onChange={onChange}
                              defaultValue={user?.areaOfFocus?.map((area) =>
                                Number(area.id)
                              )}
                            >
                              {entities.areaCoverages?.map((area, index) => (
                                <Row key={index.toString()}>
                                  <Col>
                                    <Checkbox
                                      value={area.id}
                                      defaultChecked={true}
                                    ></Checkbox>
                                  </Col>
                                  <Col>{area.title}</Col>
                                </Row>
                              ))}
                            </Checkbox.Group>
                          </aside>
                        </div>
                        {/* <label className="form-check">
                          <input className="form-check-input" type="checkbox" />
                          <span className="form-check-label">
                            Organisation & IT
                          </span>
                        </label>
                        <label className="form-check">
                          <input className="form-check-input" type="checkbox" />
                          <span className="form-check-label">operation</span>
                        </label>
                        <label className="form-check">
                          <input className="form-check-input" type="checkbox" />
                          <span className="form-check-label">
                            Marketing & sales
                          </span>
                        </label>
                        <label className="form-check">
                          <input className="form-check-input" type="checkbox" />
                          <span className="form-check-label">Finance</span>
                        </label>
                        <label className="form-check">
                          <input className="form-check-input" type="checkbox" />
                          <span className="form-check-label">Strategy</span>
                        </label>
                        <label className="form-check">
                          <input className="form-check-input" type="checkbox" />
                          <span className="form-check-label">
                            Market Analyses
                          </span>
                        </label> */}

                        <h4 className="mt-4">Add Profile Image - Jpeg or Jpg 987px X 297px (72dpi)</h4>
                        
                        {user?.image && (
                          <aside className="col-lg-6">
                            <Avatar
                              size={150}
                              src={
                                <Image
                                  preview={false}
                                  src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user.image}&type=user`}
                                />
                              }
                              icon={<UserOutlined />}
                              className="mb-1"
                            />
                          </aside>
                        )}
                        <Upload {...props} maxCount={1}>
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      </section>

                      <section className="tab">
                        <h4 className="mt-4">Consultant Profile Form</h4>
                        <div className="row">
                          <aside className="col-lg-12">
                            <Item name="Biodata" label="Write Bio">
                              <textarea
                                className="textarea"
                                placeholder="Write Bio"
                              ></textarea>
                            </Item>
                          </aside>
                          <aside className="col-lg-12">
                            <Item name="AboutUs" label="Write About">
                              <textarea
                                className="textarea"
                                placeholder="Write About"
                              ></textarea>
                            </Item>
                          </aside>
                        </div>

                        <div className="row">
                          <h4 className="mt-4">Social Media Links</h4>
                          <aside className="col-lg-4">
                            <Item name="Facebook" label="Facebook Profile">
                              <input
                                className="input-text"
                                type="text"
                                placeholder="Facebook Profile"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-4">
                            <label></label>
                            <Item name="Linkedin" label="LinkedIn Profile">
                              <input
                                className="input-text"
                                type="text"
                                placeholder="LinkedIn Profile"
                              />
                            </Item>
                          </aside>
                          <aside className="col-lg-4">
                            <Item name="Twitter" label="Twitter Profile">
                              <input
                                className="input-text"
                                type="text"
                                placeholder="Twitter Profile"
                              />
                            </Item>
                          </aside>
                        </div>
                      </section>

                      <div style={{ overflow: "auto" }}>
                        <div className="form-buttons">
                          <button
                            type="button"
                            id="prevBtn"
                            onClick={() => window.nextPrev(-1, "tab")}
                          >
                            Previous
                          </button>
                          <button
                            type="button"
                            id="nextBtn"
                            onClick={() => window.nextPrev(1, "tab")}
                          >
                            <i className="fas fa-arrow-right"></i>Next
                          </button>
                        </div>
                      </div>
                      <div style={{ textAlign: "center", marginTop: "40px" }}>
                        <span className="step"></span>
                        <span className="step"></span>
                        <span className="step"></span>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConsultantTemplate>
    </>
  );
};

const ExperienceFields = (props: {
  index: number;
  //   setState: (ob: any) => void;
}) => {
  //   const updateField = (field: string, value: string) => {
  //     props.setState((prevState: any) => ({
  //       ...prevState,
  //       Experience: [
  //         ...prevState.Experience,
  //         prevState.Experience.map((exp: any) =>
  //           exp.id === props.index ? { ...exp, [field]: value } : exp
  //         ),
  //       ],
  //     }));
  //     // props.form.setFieldValue("Experience", state);
  //   };

  return (
    <>
      <aside className="col-lg-3">
        <Item name={`PositionTitle${props.index}`} label="Position Title">
          <input
            className="input-text"
            placeholder="Enter Position Title"
            // onChange={(e) =>
            //   updateField(`PositionTitle${props.index}`, e.target.value)
            // }
          />
        </Item>
      </aside>
      <aside className="col-lg-3">
        <Item name={`PositionCompanyTitle${props.index}`} label="Company Name">
          <input
            className="input-text"
            placeholder="Enter Company"
            // onChange={(e) =>
            //   updateField(`PositionCompanyTitle${props.index}`, e.target.value)
            // }
          />
        </Item>
      </aside>
      <aside className="col-lg-3">
        <Item
          name={`ExperienceFrom${props.index}`}
          label="Years of Experience From"
        >
          <input
            className="input-text"
            type="date"
            placeholder="Enter Years of Experience From"
            // onChange={(e) =>
            //   updateField(`ExperienceFrom${props.index}`, e.target.value)
            // }
          />
        </Item>
      </aside>
      <aside className="col-lg-3">
        <Item
          name={`ExperienceTo${props.index}`}
          label="Years of Experience To"
        >
          <input
            className="input-text"
            type="date"
            placeholder="Enter Years of Experience To"
            // onChange={(e) =>
            //   updateField(`ExperienceTo${props.index}`, e.target.value)
            // }
          />
        </Item>
      </aside>
    </>
  );
};
