import { Link, useLocation } from "react-router-dom";
import ConsultantTemplate from "../../Template";
import moment from "moment";
import { Space, Tooltip } from "antd";
import * as routes from "../../../../constants/routes";
import config from "../../../../config";
import { WechatOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../../hooks";

const ConsultantInquiryViewPage = () => {
  const { user } = useAppSelector((state) => state.auth);
  
  const location = useLocation();

  const { inquiry } = (location.state as any) || {};
  return (
    <>
      <ConsultantTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Inquiry View </h1>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <h4>Project Info</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <b>Project Title</b>
                            </td>
                            <td>{inquiry?.title}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Industry focus</b>
                            </td>
                            <td>{inquiry?.industry?.title}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Specification of functional focus</b>
                            </td>
                            <td>{inquiry?.specification}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Project description</b>
                            </td>
                            <td>{inquiry?.description}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Attached File</b>
                            </td>
                            <td>
                              {
                                <Space direction="vertical">
                                  {inquiry?.images?.forEach((img: any) => {
                                    <Link
                                      to={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${img.fileName}&type=inquiry`}
                                    >
                                      {img.fileName}
                                    </Link>;
                                  })}
                                </Space>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h4 className="mt-5">Project Framework</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <b>Project Period</b>
                            </td>
                            <td>{`${moment(inquiry?.startDate).format(
                              "DD-MM-YYYY"
                            )} - ${moment(inquiry?.endDate).format(
                              "DD-MM-YYYY"
                            )} `}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Project Budget</b>
                            </td>
                            <td>{inquiry?.budget}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Start chat</b>
                            </td>
                            <td>
                              <Link
                                to={routes.TO_CHAT_APP}
                                state={{
                                  inquiry: inquiry,
                                  consultant: user,
                                }}
                              >
                                <Tooltip title="Start Chat">
                                  <WechatOutlined
                                    style={{ fontSize: "32px", color:"#D4B35F" }}
                                  />
                                </Tooltip>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConsultantTemplate>
    </>
  );
};

export default ConsultantInquiryViewPage;
