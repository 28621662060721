import { Link } from "react-router-dom";

const ConsultantMainNav = () => {
  return (
    <>
      <nav id="sidebar" className="sidebar js-sidebar">
        <div className="sidebar-content js-simplebar">
          <Link className="sidebar-brand" to="/consultant/home">
            <span className="align-middle">
              <img className="logo" src="/images/tradin.png" />
            </span>
          </Link>
          <ul className="sidebar-nav">
            <li className="sidebar-header">
              <Link to="/consultant/home">
                <i className="fas fa-home"></i> Home
              </Link>
            </li>
            <li className="sidebar-header">
              <i className="fas fa-tachometer-alt"></i> Consultant Dashboard
              <Link className="sidebar-link" to="/consultant/mainprofile">
                Profile
              </Link>
            </li>
            <li className="sidebar-header">
              <i className="fas fa-file-alt"></i> Inquiry
              <Link className="sidebar-link" to="/consultant/inquiries">
                View New Inquiry
              </Link>
            </li>

            <li className="sidebar-header">
              <i className="fas fa-user-edit"></i> Manage ID
              <Link className="sidebar-link" to="/consultant/manageprofile">
                Manage Profile
              </Link>
              <Link className="sidebar-link" to="/consultant/forgotpwd">
                Password Reset
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default ConsultantMainNav;
