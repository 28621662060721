import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Row } from "antd";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import * as AuthService from "../../../store/auth/auth.actions";
import { displaySuccessNotification } from "../../../utils/notifications";
import { resetListState } from "../../../store/lists/lists.slice";
import { resetInquiryState } from "../../../store/inquiry/inquiry.slice";
import { resetAuthState } from "../../../store/auth/auth.slice";
import { RootState } from "../../../types";
import { UserType } from "../../../enums";
import CustomerMainNav from "../../Customer/MainNav";
import ConsultantMainNav from "../../Consultant/MainNav";

export const ChatTemplate = (props: { children: ReactNode }) => {
  const { user } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const onLogOut = () => {
    dispatch(AuthService.logOut());
    dispatch(resetAuthState());
    dispatch(resetInquiryState());
    dispatch(resetListState());
    displaySuccessNotification("You've successfully logged out!");
  };

  return (
    <>
      <div className="wrapper">
        {user.userType === UserType.CUSTOMER && <CustomerMainNav />}

        {user.userType === UserType.CONSULTANT && <ConsultantMainNav />}

        <div className="main">
          <nav className="navbar navbar-expand navbar-light navbar-bg">
            <a className="sidebar-toggle js-sidebar-toggle">
              <i className="hamburger align-self-center"></i>
            </a>

            <div className="navbar-collapse collapse">
              <ul className="navbar-nav navbar-align">
                <li className="nav-item dropdown">
                  <a
                    className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <i className="align-middle" data-feather="settings"></i>
                  </a>
                  <a
                    className="nav-link dropdown-toggle d-none d-sm-inline-block"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <Row gutter={16}>
                      <Col>
                        <span>{user.firstName}</span>
                      </Col>
                      <Col>
                        <Avatar icon={<UserOutlined />} />
                      </Col>
                    </Row>
                  </a>
                  
                  <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="#">
                Profile
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="#">
                Settings
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="#" onClick={onLogOut}>
                  Log out
                </Link>
              </div>
                </li>
              </ul>
            </div>
          </nav>
          <main className="content">{props.children}</main>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-6 text-start">
                <p className="mb-0">
                <a className="text-muted" href="/home" target="_blank">
                  <strong>TradInn Ltd.</strong>
                </a>
              </p>
            </div>
            <div className="col-6 text-end">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a className="text-muted" href="#" target="_blank">
                  Copyrights © 2024 | All Rights Reserved
                  </a>
                </li>
                </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default ChatTemplate;
