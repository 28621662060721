import React from "react";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useAppDispatch } from "../../hooks";
import * as AuthService from "../../store/auth/auth.actions";
import { resetAuthState } from "../../store/auth/auth.slice";
import { resetSupplierState } from "../../store/supplier/supplier.slice";
import { resetInquiryState } from "../../store/inquiry/inquiry.slice";
import { resetQuoteState } from "../../store/quotation/quotation.slice";
import { resetListState } from "../../store/lists/lists.slice";
import { displaySuccessNotification } from "../../utils/notifications";
import { Navigate } from "react-router-dom";

export const LogoutPage = () => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(AuthService.logOut());
    dispatch(resetAuthState());
    dispatch(resetSupplierState());
    dispatch(resetInquiryState());
    dispatch(resetQuoteState());
    dispatch(resetListState());

    displaySuccessNotification("You've successfully logged out!");
  });

  return <Navigate to={"/"} />;
}
