const prod = {
  url: {
    API_URL: "https://api.tradinn.org.uk/api",
  },
  firebase: {
    apiKey: "AIzaSyDOQ_J8XQ43D52vwXdmVa8VowkMuwBJi8w",
    authDomain: "tradinn-cfde0.firebaseapp.com",
    databaseURL: "https://tradinn-cfde0-default-rtdb.firebaseio.com",
    projectId: "tradinn-cfde0",
    storageBucket: "tradinn-cfde0.appspot.com",
    messagingSenderId: "769304893438",
    appId: "1:769304893438:web:66c4ffbdc58344357b92af",
    measurementId: "G-65NL53KCLL",
  },
  google_maps_key: "AIzaSyCYi4BPs1ViGtX9z6N035mdHNVmECcRX70",
};

const dev = {
  url: {
    API_URL: "https://localhost:44370/api",
  },
  firebase: {
    apiKey: "AIzaSyDOQ_J8XQ43D52vwXdmVa8VowkMuwBJi8w",
    authDomain: "tradinn-cfde0.firebaseapp.com",
    databaseURL: "https://tradinn-cfde0-default-rtdb.firebaseio.com",
    projectId: "tradinn-cfde0",
    storageBucket: "tradinn-cfde0.appspot.com",
    messagingSenderId: "769304893438",
    appId: "1:769304893438:web:66c4ffbdc58344357b92af",
    measurementId: "G-65NL53KCLL",
  },
  google_maps_key: "AIzaSyBAwlXYDbl6eeVRb0dlDyQo8Pv7ud6rJrY",
};

export const envConfig = process.env.NODE_ENV === "development" ? dev : prod;
