import { ReactNode } from "react";
import AdminNav from "./AdminNav";
import AdminProfileNav from "./AdminProfileNav";
import AdminFooter from "./AdminFooter";

const AdminTemplate = (props: { children: ReactNode }) => {
  return (
    <>
      <div className="wrapper">
        <AdminNav />

        <div className="main">
          <AdminProfileNav />
          <main className="content">{props.children}</main>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};

export default AdminTemplate;