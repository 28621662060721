import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ConsultantProfileNav from "../../ProfileNav";
import ConsultantMainFooter from "../../MainFooter";
import ConsultantMainNav from "../../MainNav";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { InquiryResponse } from "../../../../types";
import * as InquiryService from "../../../../store/inquiry/inquiry.actions";
import moment from "moment";
import ConsultantTemplate from "../../Template";
import { Spin } from "antd";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../../../utils/notifications";

export const ConsulatantInquiryPage = () => {
  const dispatch = useAppDispatch();

  const { inquiries, status: inquiryStatus } = useAppSelector(
    (state) => state.inquiry
  );

  React.useEffect(() => {
    dispatch(InquiryService.fetchConsultantInquiries(0));
  }, []);

  const [pageState, setPageState] = useState<any[]>();

  const updateInquiry = (id: number) => {
    if (id > 0) {
      const selected = pageState?.find((pg) => pg.id === id);
      if (selected) {
        dispatch(
          InquiryService.updateInquiryStatus({
            inquiryid: id,
            status: selected.status,
          })
        );
      }
    }
  };

  const handleChange = (id: number, pstatus: number) => {
    setPageState((prevState: any) =>
      prevState.map((item: any) => {
        if (item.id === id) {
          return { ...item, status: pstatus };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    if (inquiryStatus === "fetchSupplierInquiriesResolved") {
      setPageState(
        inquiries.map((inq) => {
          return { id: inq.id, status: 0 };
        })
      );
    }
    if (inquiryStatus === "updateInquiryResolved") {
      displaySuccessNotification("Inquiry has been updated.");
    }

    if (inquiryStatus === "updateInquiryRejected") {
      displayErrorMessage("Unable to update the inquiry.");
    }
  }, [inquiryStatus]);

  return (
    <>
      <ConsultantTemplate>
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Incoming Inquires</h1>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th scope="col">S.No </th>
                          <th scope="col">Submission Date</th>
                          <th scope="col">Project Name</th>
                          <th scope="col">Customer</th>
                          <th scope="col">Inquiry Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {inquiries.map((inq, index) => (
                          <tr>
                            <th scope="row">{(index + 1).toString()}</th>
                            <td>{`${moment(inq.startDate).format(
                              "DD-MM-YYYY"
                            )} - ${moment(inq.endDate).format(
                              "DD-MM-YYYY"
                            )} `}</td>
                            <td>{inq.title}</td>
                            <td>{(inq.user as any)?.firstName}</td>
                            {/* <td>
                              {inq.status === 1
                                ? "Posted"
                                : inq.status === 2
                                ? "Declined"
                                : inq.status === 3
                                ? "Completed"
                                : ""}
                            </td> */}
                            <td>
                              <select
                                disabled={(inq.status || 0) > 1}
                                className="form-select"
                                onChange={(e) =>
                                  handleChange(
                                    inq.id || 0,
                                    Number(e.target.value)
                                  )
                                }
                              >
                                <option value="" disabled selected hidden>
                                  Choose Status
                                </option>
                                <option value="2" selected={inq.status === 2}>
                                  Accepted
                                </option>
                                {/* <option value="3" selected={inq.status === 3}>
                                  Completed
                                </option> */}
                                <option value="3" selected={inq.status === 3}>
                                  Declined
                                </option>
                              </select>
                            </td>
                            <td>
                              <Link
                                className="btn-view"
                                to="/consultant/inquiry/view"
                                state={{ inquiry: inq }}
                              >
                                View
                              </Link>
                              {(inq.status || 2) < 2 && (
                                <Link
                                  className="btn-view"
                                  to="#"
                                  onClick={() => updateInquiry(inq.id || 0)}
                                >
                                  <span id="button-text">
                                    {inquiryStatus ===
                                    "updateInquiryPending" ? (
                                      <Spin size="small" />
                                    ) : (
                                      "Update"
                                    )}
                                  </span>
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConsultantTemplate>
    </>
  );
};
